import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import LoadingComponent from './loadingComponent';
import Profile from './components/Profile';
import Products from './components/Products';
import WhatsappAccount from './components/WhatsappAccount';
import Catalog from './components/Catalog';
import Orders from './components/Orders';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setToken } from './actions';

export interface WhatsAppAccountInterface {
  id: number;
  whatsapp_name: string;
  phone_number_id: number;
  waba_id: number; // Make avatar property optional
  //meta_access_token: string;
}

export interface Product {
  availability: string;
  sku: string;
  brand: string; // Make avatar property optional
  title: string;
  category: string;
  condition: string;
  currency: string;
  description: string;
  price: number;
  sales_price: number;
  sellerId: number;
  stock: number;
  image_url: string;
  url: string;
}

export interface CatalogInterface {
  id: number;
  name: string;
  meta_catalog_id: string;
  sellerId: any;
  whatsappAccount: any;
  products:Product[];
}



const App: React.FC = () => {
  // const [sellerData, setSellerData] = React.useState<any>(null);
  // const [token, setToken] = React.useState<any>(localStorage.getItem('token'));
  const [whatsappAccount, setWhatsappAccount] = React.useState<WhatsAppAccountInterface[]>([]);
  const [catalog, setCatalog] = React.useState<CatalogInterface[]>([]);
  const [seller, setSeller] = React.useState<any>(null);
  const [sellerData, setSellerData] = React.useState<any>(null);
  const [whatsappCatalog,setWhatsappCatalog]=React.useState<any>();
  const [categories,setCategories]=React.useState<any>();
  const dispatch = useDispatch();
  // const sellerData = useSelector((state: any) => state.sellerData.sellerData)
  const token = useSelector((state:any)=>state.auth.token)

  // Define a function to update sellerData
  const updateSellerData = (data: any) => {
    setSellerData(data);
    setSeller(data)
  };

  React.useEffect(()=>{
    const sellerJSON = localStorage.getItem('sellerData');
    if(sellerJSON){
      const sellerData = JSON.parse(sellerJSON);
      updateSellerData(sellerData)
    }  
     // Add an Axios interceptor to handle token expiration globally
     const storedToken = localStorage.getItem('token');
if (storedToken) {
    dispatch(setToken(storedToken));
}
   
  },[]);

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/login" element={<Login/>} />
         {sellerData !==null && token ? (
             <Route path="/dashboard" element={<Dashboard  />} />
             ) : (
            <Route path="/dashboard" element={<LoadingComponent />}/> // You can replace this with a loading indicator
          )}
         
          <Route path="/" element={<Navigate to="/login" />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;

