export const SET_TOKEN = 'SET_TOKEN';
export const SET_SELLER_DATA = 'SET_SELLER_DATA';
export const SET_SELLER = 'SET_SELLER';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_WHATSAPP = 'SET_WHATSAPP';
export const SET_WHATSAPP_CATALOG = 'SET_WHATSAPP_CATALOG';
export const SET_CATALOG = 'SET_CATALOG';

export const setToken = (token: string) => ({
  type: SET_TOKEN,
  payload: token,
});

export const setSellerData = (sellerData: any) => ({
    type: SET_SELLER_DATA,
    payload: sellerData,
  });

  export const setSeller = (seller: any) => {
    // Assuming `seller.data` contains the data you want to pass as payload
    const payload = seller && seller.data ? seller.data : null;
    console.log('New seller state:', payload);
  
    return {
      type: SET_SELLER,
      payload: payload,
    };
  };

  // Create Redux Actions
  export const setCategories = (categories:any) => ({
  type: 'SET_CATEGORIES',
  payload: categories,
});

export const setWhatsapp = (whatsapp:any)=>({
  type:'SET_WHATSAPP',
  payload: whatsapp,
})

export const setWhatsappCatalog = (whatsappCatalog:any)=>({
  type:'SET_WHATSAPP_CATALOG',
  payload: whatsappCatalog,
})

export const setCatalog = (catalog:any)=>({
  type:'SET_CATALOG',
  payload: catalog,
})


