import React, { useEffect, useState } from 'react';
import { DownOutlined, MenuFoldOutlined, UserOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import type { MenuProps, TableColumnsType } from 'antd';
import { Badge, Button, Dropdown, Space, Table, Input, Modal, Menu, Col, Row, Card, Tag, Form, Drawer, AutoComplete, message, Descriptions, Select } from 'antd';
import axios from 'axios';
import "./index.css";
import { PlusCircleOutlined, MoreOutlined, SearchOutlined,MinusCircleOutlined } from '@ant-design/icons';
import { Layout, theme } from 'antd';
import MenuIcon from '../majesticons_menu-line.svg';
import EyeIcon from '../ph_eye.svg';
import EditIcon from '../iconamoon_edit-thin.svg';
import DeleteIcon from '../DeleteOutlined.svg';
import Search from 'antd/es/input/Search';
import TickIcon from '../mdi_tick.svg';
import { setCatalog } from '../actions';
import { useDispatch, useSelector } from 'react-redux';
//import './products.css';

const { Header, Sider, Content } = Layout;

enum ProductStatus {
    active = 'active',
    inactive = 'inactive',
    // Add more status values if needed
}


interface CustomOptionProps {
    value: string;
    data: any;
    children:any;
  }
export const CustomOption: React.FC<CustomOptionProps> = ({ value, data, children }) => (
    <Select.Option value={value} data={data}>
      {children}
    </Select.Option>
  );  


export interface Product {
    id: number;
    availability: string;
    sku: string;
    brand: string; // Make avatar property optional
    title: string;
    category: string;
    condition: string;
    currency: string;
    description: string;
    price: number;
    sales_price: number;
    sellerId: number;
    stock: number;
    image_url: string;
    url: string;
}

const mockVal = (str: string, repeat = 1) => ({
    value: str.repeat(repeat),
});


interface ExpandedDataType {
    id: number;
    whatsappAccount: string;
}
interface Sets {
    id: number;
    set_name: string;
    catalog: string;
    childSets: [];
}


const Categories: React.FC<any> = (props) => {
    const [expandedRowKeys, setExpandedRowKeys] = React.useState<React.Key[]>([]);
    const [open, setOpen] = React.useState(false);
    const { categories, setCategories } = props;
    const [options, setOptions] = React.useState<{ value: string; text: string }[]>([]);
    const [subOptions, setSubOptions] = React.useState<{ value: string; text: string }[]>([]);
    const [totalOptions, setTotalOptions] = React.useState<{ value: string; text: string }[]>([]);// Your autocomplete options
    const [inputValue, setInputValue] = React.useState('');
    const [parentValue, setParentValue] = React.useState('');
    const [selectedChips, setSelectedChips] = React.useState<string[]>([]);
    const [data, setData] = React.useState<any[]>([]);
    const [productData, setProductData] = React.useState<Product[]>([]);
    const [categoriesData, setCategoriesData] = React.useState<any[]>([]);
    const [originalCategoriesData, setOriginalCategoriesData] = useState<any[]>([]);
    const [expandData, setExpandData] = React.useState<any[]>([]);
    const [value, setValue] = React.useState('');
    const [expandedTab, setExpandedTab] = useState(null);
    const catalog = useSelector((state:any)=>state.catalog.catalog);
    const [expandedSubTab, setSubExpandedTab] = useState<number | null>(null);
    const [dataSource, setDataSource] = React.useState<{ value: string }[]>([]);
    const [parentId, setParentId] = React.useState();
    const [selectedTab, setSelectedTab] = useState(null);
    const [searchTerm, setSearchTerm] = React.useState('');
    const [searchProduct, setSearchProduct] = React.useState('');
    const [modalOpen, SetModalOpen] = React.useState(false);
    const [isModalOpen, SetIsModalOpen] = React.useState(false);
    const [selectedCategory, setSelectedCategory] = useState<Sets | null>(null);
    const [selectedSubCategory, setSelectedSubCategory] = useState<Sets | null>(null);
    const [showInputBox, setShowInputBox] = useState(false);
    const [inputCategory, setInputCategory] = useState('');
    const [description, setDescription] = useState('');
    const [showProduct, setShowProduct] = useState(false);
    const [product, setProduct] = React.useState<any>();
    const [editProduct, setEditProduct] = React.useState<any>();
    const [productDeleteModal, setProductDeleteModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [parentEditModal, setParentEditModal] = useState(false);
    const [parentDeleteModal, setParentDeleteModal] = useState(false);
    const [childDeleteModal, setChildDeleteModal] = useState(false);
    const [parentCategoryName, setParentCategoryName] = useState('');
    const dispatch = useDispatch();
    const seller = useSelector((state:any)=>state.seller.seller);
    const [selectedSubCategoryName, setSelecetedSubCategoryName] = useState('');
    const [selectedCatalog, setSelectedCatalog] = React.useState<{
        id: number;
        name: string;
        meta_catalog_id: string;
        created_at: string;
        updated_at: string;
        whatsappAccount: {
            id: number;
            whatsapp_name: string;
            phone_number_id: string;
            waba_id: string;
            meta_access_token: string;
            created_at: string;
            updated_at: string;
        };
        seller: {
            id: number;
            first_name: string;
            last_name: string;
            password: string;
            phone: string;
            token: string;
            email: string;
            business_id: string;
            retailer_id: string | null;
            created_at: string;
            updated_at: string;
        };
    } | null>(null);
    const [showTabs, setShowTabs] = useState(false);
    const [showTab, setShowTab] = useState(false);
    const [selectedChatbot, SetSelectedChatbot] = useState('');
    const [selectChatbot, SetSelectChatbot] = React.useState<Product[]>([]);

    const toggleTabs = () => {
        setShowTabs(!showTabs);
    };
    const tabMenuToggle = (item: any) => {
        console.log("Catalog Item", item)
        setSelectedCatalog(item)
        SetSelectChatbot(item.products)
        if (selectedTab === item.id) {
            // Collapse the active tab when clicked again
            SetSelectedChatbot(item.name);
            const filteredObjects = data.filter(obj => obj.catalog.name === item.name);
            setCategoriesData(filteredObjects);
            setOriginalCategoriesData(filteredObjects)
            setSelectedTab(null);
        } else {
            SetSelectedChatbot(item.name);
            const filteredObjects = data.filter(obj => obj.catalog.name === item.name);
            setCategoriesData(filteredObjects);
            setOriginalCategoriesData(filteredObjects)
            setSelectedTab(item.id);
        }
    }

    async function getCatalog(seller: any) {
        console.log("Categories getCatalog seller", seller)
        const catalogs = await axios.get(`https://wc.adaptnxt.in/catalog/${seller.id}/getCatalog`);
        console.log('catalogs', catalogs)
        dispatch(setCatalog(catalogs?.data));
        // setCatalog(catalogs?.data)
        setSelectedCatalog(catalogs?.data[0])
        SetSelectedChatbot(catalogs?.data[0]?.name)
        SetSelectChatbot(catalogs?.data[0]?.products)
    }
    async function getSets(seller: any) {
        try {
            const sets = await axios.get(`https://wc.adaptnxt.in/sets/${seller.id}/getAllSets`);
            console.log("Total sets response")
            console.log("Response Data:", sets.data); // Log the response data
            console.log("Response Status:", sets.status); // Log the response status
            if (sets.data) {
                dispatch(setCatalog(sets.data?.catalogs));
                // setCatalog(sets.data?.catalogs)
                setSelectedCatalog(sets.data?.catalogs[0])
                SetSelectedChatbot(sets.data?.catalogs[0].name)
                SetSelectChatbot(sets.data?.catalogs[0].products)
                setData(sets?.data?.sets);
                setCategoriesData(sets?.data?.sets);
                setOriginalCategoriesData(sets?.data?.sets);
            }
        } catch (error) {
            console.error("Error fetching sets:", error);
        }
    }

    

    React.useEffect(() => {
        //const sellerJSON = localStorage.getItem('seller');
            getSets(seller);
    }, []);
    useEffect(() => {
        if (searchTerm === '') {
            setCategoriesData(originalCategoriesData);
        }
    }, [searchTerm, originalCategoriesData]);

    const getPanelValue = (searchText: string) =>
        !searchText ? [] : [mockVal(searchText), mockVal(searchText, 2), mockVal(searchText, 3)];

    const onSelect = (data: string) => {
        console.log('onSelect', data);
    };

    const onChange = (data: string) => {
        setValue(data);
    };

    const getOptions = (filteredData: any) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios.get(`https://wc.adaptnxt.in/products/${filteredData[0].id}`);
                resolve(response.data); // Resolve with the response data
            } catch (error) {
                console.error('Error fetching options:', error);
                reject(error); // Reject with the error
            }
        });
    };

    const expandedRowRender = (record: any) => {
        // let optionsFetched = false;

    };

    const onFinish = async (values: any) => {
        const sellerJSON = localStorage.getItem('seller');
        if (sellerJSON) {
            const seller = JSON.parse(sellerJSON);
            console.log("Values", values.id, editProduct)
            const product = await axios.put(`https://wc.adaptnxt.in/products/update/product/${values.id}`, {
                sellerId: seller.id,
                sku: values.sku,
                title: values.title,
                description: values.description,
                price: values.price,
                currency: values.currency,
                url: values.url,
                condition: values.condition,
                brand: values.brand,
                availability: values.availability,
                category: values.category,
                image_url: values.image_url,
                stock: values.stock
            })
            window.location.reload();
        }
    }

    const handleShowProduct = async (record: any) => {
        console.log("Product Record", record)
        setShowProduct(true);
        setProduct(record)
    }

    const handleEditModal = (record: any) => {
        setEditProduct(record)
        setEditModal(true);
    }
    const handleDeleteModal = async (record: any) => {
        setProduct(record)
        setProductDeleteModal(true)
    }

    const handleParentCategoryDelete = async () => {
        const response = await axios.delete(`https://wc.adaptnxt.in/sets/${selectedCategory?.id}`);
        setSelectedCategory(null);
        setParentDeleteModal(false);
        window.location.reload();
    }
    const handleChildCategoryDelete = async () => {
        const response = await axios.delete(`https://wc.adaptnxt.in/sets/${selectedSubCategory?.id}`);
        setSelectedCategory(null);
        setParentDeleteModal(false);
        window.location.reload();
    }

    const columns: TableColumnsType<Product> = [
        { title: 'Product Name', dataIndex: 'title', key: 'title', sorter: (a, b) => a.title.length - b.title.length, },
        { title: 'SKU', dataIndex: 'sku', key: 'sku', sorter: (a, b) => a.sku.length - b.sku.length, },
        { title: 'Price', dataIndex: 'price', key: 'price', sorter: (a, b) => a.price - b.price, },
        { title: 'Stock in hand', dataIndex: 'stock', key: 'stock', },
        // { title: 'Weight', dataIndex: 'weight', key: 'weight', sorter: (a, b) => a.weight - b.weight, },
        // { title: 'Length', dataIndex: 'dimension_length', key: 'dimension_length', sorter: (a, b) => a.dimension_length - b.dimension_length, },
        // { title: 'Breadth', dataIndex: 'dimension_breadth', key: 'dimension_breadth', sorter: (a, b) => a.dimension_breadth - b.dimension_breadth },
        // { title: 'Height', dataIndex: 'dimension_height', key: 'dimension_height', sorter: (a, b) => a.dimension_height - b.dimension_height },
        // { title: 'HS Code', dataIndex: 'hscode', key: 'hscode', sorter: (a, b) => a.hscode.length - b.hscode.length },
        {
            title: 'Action',
            dataIndex: 'operation',
            key: 'operation',
            render: (text, record) => (
                <Space size="middle" className='table-action'>
                    <div>

                        <a onClick={() => handleShowProduct(record)}><img src={EyeIcon} alt="Edit" className="edit-icon" /></a>
                        <Modal
                            visible={showProduct}
                            onCancel={() => {
                                setProduct(null)
                                setShowProduct(false)
                            }}
                            footer={null}
                            width="65%"
                            maskStyle={{ background: "rgba(255, 255, 255, 0.1)" }}
                            bodyStyle={{ maxHeight: 'max-content', overflow: 'auto' }}
                        >
                            <Row style={{ display: 'flex', justifyContent: 'center' }}>
                                <Card
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                        margin: '10px',
                                        borderRadius: '12px',
                                        minWidth: '60%',
                                        minHeight: '500px',
                                        height: '100%',
                                        boxShadow: '10px 10px 4px 0px rgba(128, 128, 128, 0.10)',
                                    }}
                                >
                                    <Row gutter={24} style={{ justifyContent: 'center', display: 'flex' }}>
                                        <Col style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                                            <img
                                                src={
                                                    product?.image_url === null
                                                        ? 'https://static.vecteezy.com/system/resources/previews/005/337/799/original/icon-image-not-found-free-vector.jpg'
                                                        : product?.image_url
                                                }
                                                width={220}
                                                height={320}
                                                style={{
                                                    borderRadius: '12px',
                                                    marginLeft: '15px',
                                                    boxShadow: '5px 5px 4px 0px rgba(128, 128, 128, 0.10)',
                                                }}
                                                alt="Product Image"
                                            />
                                        </Col>
                                        <Col style={{ paddingLeft: '30px' }}>
                                            <p style={{ fontSize: '30px', fontWeight: '400' }}>{product?.title}</p>
                                            <div style={{ width: '750px' }}>
                                                <p
                                                    style={{
                                                        fontSize: '15px',
                                                        display: '-webkit-box',
                                                        WebkitLineClamp: 4,
                                                        WebkitBoxOrient: 'vertical',
                                                        overflow: 'hidden',
                                                    }}
                                                >
                                                    {product?.description}
                                                </p>
                                            </div>
                                            <Descriptions bordered>
                                                <Descriptions.Item label="Price">{product?.sales_price}</Descriptions.Item>
                                                <Descriptions.Item label="Stock In Hand">{product?.stock}</Descriptions.Item>
                                                <Descriptions.Item label="Product SKU">{product?.sku}</Descriptions.Item>
                                            </Descriptions>
                                        </Col>
                                    </Row>
                                </Card>
                            </Row>
                        </Modal>
                    </div>
                    <div>
                        <a onClick={() => handleEditModal(record)}><img src={EditIcon} alt="Edit" className="edit-icon" /></a>
                        {editProduct && (<Modal
                            visible={editModal}
                            onCancel={() => {
                                setEditProduct(null); // Clear the currently edited product
                                setEditModal(false);
                            }}
                            footer={null}
                            width="670px"
                            bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
                        >
                            <Form name="create-account-form" onFinish={onFinish} initialValues={editProduct} >
                                <Form.Item
                                    label="Id"
                                    name="id"
                                >
                                    <Input style={{ width: "394px", height: "30px", marginLeft: "1.5rem" }} />
                                </Form.Item>
                                <Form.Item
                                    label="Product Name"
                                    name="title"
                                >
                                    <Input style={{ width: "394px", height: "30px", marginLeft: "1.5rem" }} />
                                </Form.Item>
                                <Form.Item
                                    label="Sku"
                                    name="sku"
                                >
                                    <Input style={{ width: "394px", height: "30px" }} />
                                </Form.Item>
                                <Form.Item
                                    label="Description"
                                    name="description"
                                >
                                    <Input style={{ width: "394px", height: "30px" }} />
                                </Form.Item>
                                <Form.Item
                                    label="Price"
                                    name="price"
                                >
                                    <Input style={{ width: "394px", height: "30px" }} />
                                </Form.Item>
                                <Form.Item
                                    label="Currency"
                                    name="currency"
                                >
                                    <Input style={{ width: "394px", height: "30px" }} />
                                </Form.Item>
                                <Form.Item
                                    label="Url"
                                    name="url"
                                >
                                    <Input style={{ width: "394px", height: "30px" }} />
                                </Form.Item>
                                <Form.Item
                                    label="Condition"
                                    name="condition"
                                >
                                    <Input style={{ width: "394px", height: "30px" }} />
                                </Form.Item>
                                <Form.Item
                                    label="Brand"
                                    name="brand"
                                >
                                    <Input style={{ width: "394px", height: "30px" }} />
                                </Form.Item>
                                <Form.Item
                                    label="Availability"
                                    name="availability"
                                >
                                    <Input style={{ width: "394px", height: "30px" }} />
                                </Form.Item>
                                <Form.Item
                                    label="Category"
                                    name="category"
                                >
                                    <Input style={{ width: "394px", height: "30px" }} />
                                </Form.Item>
                                <Form.Item
                                    label="Image Url"
                                    name="image_url"
                                >
                                    <Input style={{ width: "394px", height: "30px" }} />
                                </Form.Item>
                                <Form.Item
                                    label="Stock"
                                    name="stock"
                                >
                                    <Input style={{ width: "394px", height: "30px" }} />
                                </Form.Item>
                                <Form.Item>
                                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                        <Button onClick={() => setEditModal(false)}>
                                            Cancel
                                        </Button>
                                        <Button style={{ backgroundColor: '#473355', color: 'white', marginLeft: "1rem" }} htmlType="submit">
                                            Update
                                        </Button>
                                    </div>
                                </Form.Item>
                            </Form>
                        </Modal>)
                        }
                    </div>
                    <div>
                        <a onClick={() => handleDeleteModal(record)}><img src={DeleteIcon} alt="Delete" style={{ cursor: "pointer", marginRight: "10px" }} className="edit-icon" /></a>
                        {product && (<Modal
                            visible={productDeleteModal}
                            onCancel={() => {
                                setProduct(null)
                                setProductDeleteModal(false)
                            }}
                            maskStyle={{ background: "rgba(255, 255, 255, 0.1)" }}
                            footer={null}
                            width="670px"
                            bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
                        >
                            <div>
                                <p style={{ fontFamily: "DM Sans", fontWeight: 400, fontSize: "20px", color: "black" }}>Delete Product</p>
                                <hr />
                                <p style={{ fontSize: "18px", fontFamily: "DM Sans", fontWeight: 400, display: "flex", justifyContent: "center" }}>Are you sure you want to delete {product.title}?<br />This action cannot be undone.</p>
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Button key="back" onClick={() => {
                                        setProduct(null)
                                        setProductDeleteModal(false)
                                    }}>Cancel</Button>
                                    <Button key="submit" style={{ marginLeft: "1rem", backgroundColor: "#FF4D4F", color: "white" }} onClick={() => handleProductDelete(product)}>Delete</Button>
                                </div>
                            </div>
                        </Modal>)}
                    </div>
                </Space>
            ),
        },
    ];

    const handleProductDelete = async (record: any) => {
        console.log("delete Product", record);
        const product = await axios.delete(`https://wc.adaptnxt.in/products/sets/${expandedSubTab}/${record.id}`)
        window.location.reload();
    }

    const subcolumns: TableColumnsType<Product> = [
        {
            title: 'Product Name', dataIndex: 'title', key: 'title', sorter: (a, b) => a.title.length - b.title.length, filteredValue: [searchProduct], onFilter: (value, record) => {
                return String(record.title).toLocaleLowerCase().includes(String(value).toLowerCase())
            }
        },
        { title: 'SKU', dataIndex: 'sku', key: 'sku', sorter: (a, b) => a.sku.length - b.sku.length, },
        { title: 'Price', dataIndex: 'price', key: 'price', sorter: (a, b) => a.price - b.price, },
        { title: 'Stock in hand', dataIndex: 'stock', key: 'stock',sorter: (a, b) => a.stock - b.stock },
        // { title: 'Weight', dataIndex: 'weight', key: 'weight', sorter: (a, b) => a.weight - b.weight, },
        // { title: 'Length', dataIndex: 'dimension_length', key: 'dimension_length', sorter: (a, b) => a.dimension_length - b.dimension_length, },
        // { title: 'Breadth', dataIndex: 'dimension_breadth', key: 'dimension_breadth', sorter: (a, b) => a.dimension_breadth - b.dimension_breadth },
        // { title: 'Height', dataIndex: 'dimension_height', key: 'dimension_height', sorter: (a, b) => a.dimension_height - b.dimension_height },
        // { title: 'HS Code', dataIndex: 'hscode', key: 'hscode', sorter: (a, b) => a.hscode.length - b.hscode.length },
    ];

    const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [filterData, setFilterData] = React.useState<Product[]>([]);
    const [selectedMenuItem, setSelectedMenuItem] = React.useState('2');
    const [selectedRows, setSelectedRows] = React.useState<Product[]>([]);
    const [isModalVisible, setIsModalVisible] = React.useState(false);

    const showDrawer = (tab: any) => {
        setSubExpandedTab(null);
        console.log("Tab", tab)
        setExpandedTab(null);
        setSelectedCategory(tab)
        setOpen(true);
    };

    const onClose = () => {
        setSelectedSubCategory(null)
        setSelecetedSubCategoryName('');
        setSelectedRows([]);
        setSelectedRowKeys([]);
        setOpen(false);
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const renderContent = () => {
        switch (selectedMenuItem) {
            case '6':
                return (
                    <div>

                    </div>
                );
            case '7':
                return (
                    <div>
                        {/* Content for Whatsapp Accounts */}
                        {/* <Products /> */}
                    </div>
                );
            default:
                return null;
        }
    };
    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <button style={{ cursor: "pointer", width: "120px", height: "30px", borderRadius: "5px" }}>Edit</button>
            ),
        },
        {
            key: '2',
            label: (
                <button style={{ cursor: "pointer", width: "120px", height: "30px", borderRadius: "5px", marginTop: "0.5rem", backgroundColor: "#FF4D4F", color: "white" }}>Delete</button>
            ),
        },
    ];

    const start = () => {
        // ajax request after empty completing
        setTimeout(() => {

        }, 1000);
    };

    const toggleTabExpansion = (tab: any) => {
        console.log("Tab expansion", tab)
        if (expandedTab === tab.id) {
            setExpandedTab(null);
            setSelectedCategory(null);// Collapse the currently expanded tab if it's clicked again
        } else {
            setExpandedTab(tab.id);
            setSelectedCategory(tab);// Expand the clicked tab
        }
    }

    const toggleSubTabExpansion = async (tab: any) => {
        const response = await axios.get(`https://wc.adaptnxt.in/sets/${tab.id}/getSet`)
        console.log("Response", response);
        if (expandedSubTab === tab.id) {
            setSubExpandedTab(null);
            setProductData([])
            //setSelectedCategory(null);// Collapse the currently expanded tab if it's clicked again
        } else {
            setSubExpandedTab(tab.id);
            setProductData(response.data[0].products)
            //setSelectedCategory(tab);// Expand the clicked tab
        }
    }
    useEffect(() => {
        // When filterData changes, update selectedRowKeys based on the current selectio

        setSelectedRowKeys(selectedRowKeys);
    }, [filterData]);
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys, newSelectedRowKeys);
        // If there is no selectedSubCategory, update the selectedRowKeys and selectedRows based on the newSelectedRowKeys.
        setSelectedRowKeys(newSelectedRowKeys);

        // Update the selectedRows based on the newSelectedRowKeys
        let updatedSelectedRows = selectChatbot.filter((item: any) => newSelectedRowKeys.includes(item.id));
        setSelectedRows(updatedSelectedRows);
    };






    const handleMenuItemClick = async (menuItemKey: any) => {
        const selectedCatalogItem = catalog.find((item: any) => item.id === menuItemKey);
        console.log(selectedCatalogItem)
        if (selectedCatalogItem) {
            setSelectedCatalog(selectedCatalogItem); // Update the selected catalog name
        }
        // Handle the click event, perform any necessary actions
        const productOptions = await axios.get(`https://wc.adaptnxt.in/products/${menuItemKey}`)
        console.log("Product Options", productOptions)
        const newOptions = productOptions.data.data.map((item: any) => ({
            value: item.retailer_id, // Assuming 'id' is unique
            text: item.name,
        }))
        // Show the modal
        setOptions(newOptions);
        setTotalOptions(newOptions);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const handleSearch = (value: any) => {
        // Implement your own search logic here to filter options
        const filteredOptions = options.filter((option) =>
            option.text.toLowerCase().includes(value.toLowerCase())
        );
        setOptions(filteredOptions);
    };

    const handleSubSearch = (value: any) => {
        // Implement your own search logic here to filter options
        const filteredOptions = subOptions.filter((option) =>
            option.text.toLowerCase().includes(value.toLowerCase())
        );
        setSubOptions(filteredOptions);
    };

    const handleSelect = (value: any) => {
        setSelectedChips([...selectedChips, value]);
        setInputValue('');
        const updatedOptions = totalOptions.filter((option) => option.value !== value);
        setOptions(updatedOptions); // Clear the autocomplete dropdown
    };
    const handleSubSelect = (value: any) => {
        setSelectedChips([...selectedChips, value]);
        setInputValue('');
        const updatedOptions = totalOptions.filter((option) => option.value !== value);
        setSubOptions(updatedOptions); // Clear the autocomplete dropdown
    };

    const handleRemove = (chip: any) => {
        const updatedChips = selectedChips.filter((item) => item !== chip);
        setSelectedChips(updatedChips);
    };

    const handleSubSetCreation = async (values: any) => {
        const { category, } = values;
        const data = {
            set_name: category,
            catalogId: selectedCatalog?.id,
            parentSetId: parentId,
            productIds: selectedChips, // Replace with the actual productIds or remove if not needed
        };

        // Define the axios configuration
        const config = {
            method: 'post', // Change to 'put' or 'patch' if updating an existing set
            url: 'https://wc.adaptnxt.in/sets', // Replace with your API endpoint URL
            headers: {
                'Content-Type': 'application/json',
                // Add any other headers as needed
            },
            data: data,
        };

        // Send the axios request
        axios.request(config)
            .then((response) => {
                // Handle success, e.g., display a success message
                console.log('Set created successfully', response.data);
                setData((prevData: any) => [
                    ...prevData,
                    {
                        id: response.data.id,
                        set_name: response.data.set_name,
                        catalog: response.data.catalog.name
                    }
                ]);
                setOpen(false)
            })
            .catch((error) => {
                // Handle error, e.g., display an error message
                console.error('Error creating set', error);
            });
    }


    const handleSubmit = async () => {
        console.log('Input Value:', inputCategory, selectedCatalog);
        const data = {
            set_name: inputCategory,
            description: description,
            catalogId: selectedCatalog?.id, // Replace with the actual productIds or remove if not needed
        };
        const config = {
            method: 'post', // Change to 'put' or 'patch' if updating an existing set
            url: 'https://wc.adaptnxt.in/sets/parentSet', // Replace with your API endpoint URL
            headers: {
                'Content-Type': 'application/json',
                // Add any other headers as needed
            },
            data: data,
        };
        const response = await axios.request(config)
        console.log("New Category", response);
        setCategoriesData((prevCategoriesData) => [...prevCategoriesData, response.data]);
        //window.location.reload();

        // Clear the input and hide the input box
        setInputValue('');
        setShowInputBox(false);
    }

    const handleDelete = async (tab: any) => {
        console.log("Delete Tab", tab)
    }

    const handleParentSetEdit = async (tab: any) => {
        console.log("Edit Set", tab)
        setParentCategoryName(tab.set_name)
        //setSelectedCategory(tab)
        setExpandedTab(tab.id)
        setParentEditModal(true);
    }
    const handleParentSet = async () => {
        const response = await axios.put(`https://wc.adaptnxt.in/sets/parentSet/${expandedTab}`, {
            set_name: parentCategoryName,
            catalogId: selectedCatalog?.id
        })
        setExpandedTab(null)
        //setSelectedCategory(null);
        setParentCategoryName('');
        setParentEditModal(false);
        window.location.reload();
    }

    const handleParentDeleteModal = async (tab: any) => {
        setSelectedCategory(tab);
        setParentDeleteModal(true)
    }
    const handleChildDeleteModal = async (tab: any) => {
        setSelectedSubCategory(tab);
        setChildDeleteModal(true)
    }

    const handleChildSetEdit = async (tab: any) => {
        console.log("child tab", productData)
        setExpandedTab(null);
        setSelectedCategory(tab);
        const response = await axios.get(`https://wc.adaptnxt.in/sets/${tab.id}/getSet`)
        const selectedProducts = response.data[0].products.map((item: any) => item.id)
        setSelectedRows(response.data[0].products)
        setSelectedRowKeys(selectedProducts)
        localStorage.setItem('selectedRowKeys', JSON.stringify(selectedProducts));
        setSelectedSubCategory(tab);
        setSelecetedSubCategoryName(tab.set_name)
        setOpen(true)
    }

    const handleChange = (value: any) => {
        console.log("Catalog Item", value);
        const filteredItem = catalog.filter((item:any) => item.name === value);
        console.log("filtered Item",filteredItem);
        setSelectedCatalog(filteredItem[0])
        SetSelectChatbot(filteredItem[0].products)
        if (selectedTab === filteredItem[0].id) {
            // Collapse the active tab when clicked again
            SetSelectedChatbot(filteredItem[0].name);
            const filteredObjects = data.filter(obj => obj.catalog.name === filteredItem[0].name);
            setCategoriesData(filteredObjects);
            setOriginalCategoriesData(filteredObjects)
            setSelectedTab(null);
        } else {
            SetSelectedChatbot(filteredItem[0].name);
            const filteredObjects = data.filter(obj => obj.catalog.name === filteredItem[0].name);
            setCategoriesData(filteredObjects);
            setOriginalCategoriesData(filteredObjects)
            setSelectedTab(filteredItem[0].id);
        }
        console.log(`selected ${value}`);
    };


    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
                {/* <button onClick={toggleTabs} style={{ display: "flex", justifyContent: "flex-start", height: "2rem" }}>
                    <img src={MenuIcon} alt="Edit" className="edit-icon" />
                </button> */}
                <p style={{ fontSize: "20px", fontFamily: "DM Sans", fontWeight: 400, marginLeft: "10px" }}>Create categories for your chatbot</p>
            </div>
            {/* {showTabs && ( */}
            <div style={{ display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
                <Select
                    value={selectedChatbot}
                    style={{ width: 180 }}
                    onChange={handleChange}
                    optionLabelProp="label"
                >
                    {catalog?.map((item: any) => (
                        <Select.Option key={item.id} value={item.name} label={item.name}>
                            {item.name}
                        </Select.Option>
                    ))}
                </Select>
            </div>
            {/* {selectedTab !== null && ( */}
            <div
                style={{
                    position: "relative",
                    //top: "10px", // Adjust the top position as needed
                    left: "185px", // Adjust the left position when buttons are collapsed
                    width:"calc(100% - 185px)", // Adjust the width when buttons are collapsed
                    height: "700px", // Adjust the height as needed
                    border: "1px solid gray",
                    marginTop: "1rem",
                    zIndex: 1,
                    overflow: "auto",
                    background: "#F6F8FB" // Ensure it appears above other elements
                }}
            >
                <div style={{ display: "flex", justifyContent: "flex-start", flexDirection: "column", marginLeft: "40px" }}>
                    <p style={{ width: "300px", height: "22px", fontFamily: "DM Sans", fontSize: "18px", fontWeight: 500, display: "flex", justifyContent: "left" }}>{selectedChatbot}</p>
                    <p style={{ width: "335px", height: "22px", fontFamily: "DM Sans", fontSize: "14px", fontWeight: 400, color: "rgba(0, 0, 0, 0.50)", marginTop: "-1rem", display: "flex", justifyContent: "left" }}>Categorize Product Listings in Chatbot</p>
                </div>
                <div style={{ marginBottom: "1rem", display: "flex", justifyContent: "space-between" }}>
                    <button
                        onClick={() => {
                            // Handle button click action
                            setShowInputBox(true)
                            console.log(`Button clicked`);
                        }}
                        style={{
                            border: 'none',
                            marginLeft: "2.5rem",
                            width: "150px",
                            height: "30px",
                            fontFamily: "DM Sans",
                            fontSize: "12px",
                            fontWeight: 400,
                            backgroundColor: "#473355",
                            color: "white",
                            borderRadius: "5px"
                        }}
                    >
                        <PlusCircleOutlined /> Add new category
                    </button>
                    <input
                        type="text"
                        placeholder="Search category"
                        value={searchTerm}
                        onChange={(e) => {
                            setSearchTerm(e.target.value)
                            const originalData = categoriesData;
                            if (searchTerm !== '') {
                                const filtered = categoriesData.filter(category =>
                                    category.set_name.toLowerCase().includes(searchTerm.toLowerCase())
                                );
                                setCategoriesData(filtered)
                            }
                        }}
                        onKeyUp={(e) => {
                            if (e.key === 'Enter') {
                                const filtered = categoriesData.filter((category) =>
                                    category.set_name.toLowerCase().includes(searchTerm.toLowerCase())
                                );
                                setCategoriesData(filtered);
                            }
                        }}
                        style={{ width: "320px", height: "28px", borderRadius: "5px", marginRight: "2rem" }}
                    />
                </div>
                <div >
                    {showInputBox && (
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "95%", height: "41px", border: "0.5px solid #473355", marginLeft: "2.5rem", marginBottom: "1rem" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <p style={{ marginLeft: "1rem" }}>Category Name:</p>
                                <input
                                    type="text"
                                    placeholder="Enter category name"
                                    value={inputCategory}
                                    onChange={(e) => setInputCategory(e.target.value)}
                                    style={{ border: "none", outline: "none", marginLeft: "1rem", height: "30px" }}
                                />
                            </div>
                            <div style={{ display: "flex", alignItems: "center", marginRight: "5rem" }}>
                                <p>Description:</p>
                                <input
                                    type="text"
                                    placeholder="Enter description"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    style={{ border: "none", outline: "none", marginLeft: "1rem", height: "30px", width: "250px" }}
                                />
                            </div>
                            <button
                                onClick={handleSubmit}
                                style={{
                                    border: 'none',
                                    width: '80px',
                                    height: '30px',
                                    fontFamily: 'DM Sans',
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    color: 'white',
                                    borderRadius: '5px',

                                }}
                            >
                                <img src={TickIcon} alt="Edit" className="edit-icon" />
                            </button>
                        </div>
                    )}
                    {

                        categoriesData.map((tab, index, array) => {
                            // Check if parentSet is not null or undefined
                            if (tab.parentSet === null && array[index].parentSet === null) {
                                const isSameAsPrevious = index > 0 && tab.set_name === array[index - 1].set_name;
                                // Check if the current tab's set_name is the same as the previous one (if not the first item)
                                return (
                                    <>
                                        <div
                                            key={tab.id}
                                            style={{
                                                // Tab style here, adjust as needed
                                                cursor: 'pointer',
                                                marginBottom: '1rem',
                                                display: isSameAsPrevious ? 'none' : "block",
                                                width: "95%",// Hide duplicates,
                                                marginLeft: "2.5rem",
                                                border: "0.5px solid #473355",
                                                maxHeight: '400px',
                                                overflowY: "auto"
                                            }}
                                        //onClick={() => toggleTab(tab.id)}
                                        >
                                            <div style={{ display: isSameAsPrevious ? 'none' : "flex", }} >
                                                <div style={{ marginLeft: "1rem", marginTop: "1.5rem" }} onClick={() => toggleTabExpansion(tab)}>
                                                    {expandedTab === tab.id ?<MinusCircleOutlined />:<PlusCircleOutlined />}
                                                </div>
                                                <div>
                                                    <p style={{ fontSize: '18px', fontWeight: 500, marginLeft: "0.5rem" }}>
                                                        {tab.set_name}
                                                    </p>
                                                </div>
                                                <button
                                                    onClick={() => {
                                                        showDrawer(tab)
                                                    }}
                                                    style={{
                                                        border: 'none',
                                                        marginLeft: 'auto',
                                                        marginRight: "2rem",
                                                        marginTop: "1.1rem",
                                                        width: "150px",
                                                        height: "30px",
                                                        fontFamily: "DM Sans",
                                                        fontSize: "12px",
                                                        fontWeight: 400,
                                                        backgroundColor: "#473355",
                                                        color: "white",
                                                        borderRadius: "5px",
                                                        cursor: "pointer"
                                                    }}
                                                >
                                                    <PlusCircleOutlined /> Add sub category
                                                </button>
                                                {
                                                    tab ? (
                                                        <Dropdown menu={{
                                                            items, onClick: ({ key }) => {
                                                                if (key === '1') handleParentSetEdit(tab);
                                                                // else if (key === '2')handleCloseTask
                                                                if (key === '2') handleParentDeleteModal(tab)
                                                            }
                                                        }} placement="bottomRight">
                                                            <MoreOutlined style={{ cursor: "pointer" }} />
                                                        </Dropdown>
                                                    ) : (
                                                        <></>
                                                    )
                                                }

                                                <Modal
                                                    visible={isModalOpen}
                                                    onCancel={() => SetIsModalOpen(false)}
                                                    footer={null}
                                                    width="162px"
                                                >
                                                    <div style={{ display: "flex", flexDirection: "column", marginTop: "1.8rem" }}>
                                                        <p>Delete {tab.set_name}</p>
                                                        <button style={{ width: "120px", height: "30px", borderRadius: "5px" }}>Edit</button>
                                                        <button onClick={() => handleDelete(tab)} style={{ width: "120px", height: "30px", borderRadius: "5px", marginTop: "1rem", backgroundColor: "#FF4D4F", color: "white" }}>Delete</button>
                                                    </div>
                                                </Modal>
                                                <Modal
                                                    visible={parentDeleteModal}
                                                    onCancel={() => {
                                                        setParentDeleteModal(false)
                                                        setSelectedCategory(null)
                                                    }}
                                                    footer={null}
                                                    width="500px"
                                                >
                                                    <div>
                                                        <p style={{ fontFamily: "DM Sans", fontWeight: 400, fontSize: "20px", color: "black" }}>Delete Category</p>
                                                        <hr />
                                                        <p style={{ fontSize: "18px", fontFamily: "DM Sans", fontWeight: 400, display: "flex", justifyContent: "center" }}>Are you sure you want to delete {selectedCategory?.set_name}?<br />This action cannot be undone.</p>
                                                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                                            <Button key="back" onClick={() => {
                                                                setSelectedCategory(null)
                                                                setParentDeleteModal(false)
                                                            }}>Cancel</Button>
                                                            <Button key="submit" style={{ marginLeft: "1rem", backgroundColor: "#FF4D4F", color: "white" }} onClick={() => handleParentCategoryDelete()}>Delete</Button>
                                                        </div>
                                                    </div>
                                                </Modal>
                                                <Modal
                                                    visible={parentEditModal}
                                                    onCancel={() => {
                                                        setParentEditModal(false)
                                                        setSelectedCategory(null)
                                                        setParentCategoryName('')
                                                    }}
                                                    footer={null}
                                                    width="500px"
                                                >
                                                    <div>
                                                        <p style={{ fontFamily: "DM Sans", fontWeight: 400, fontSize: "20px", color: "black" }}>Edit Category</p>
                                                        <hr />
                                                        <input
                                                            type="text"
                                                            placeholder="Edit Parent Set Category"
                                                            value={parentCategoryName}
                                                            onChange={(e) => setParentCategoryName(e.target.value)}
                                                            style={{ border: "1px solid #473355", width: "440px", height: "25px", borderRadius: "5px", background: "var(--neutral-1, #FFF)" }}
                                                        />
                                                        <p style={{ fontSize: "18px", fontFamily: "DM Sans", fontWeight: 400, display: "flex", justifyContent: "center", marginTop: "1rem" }}>Are you sure you want to Edit?</p>
                                                        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "1rem" }}>
                                                            <Button key="back" onClick={() => {
                                                                setSelectedCategory(null)
                                                                setParentCategoryName('')
                                                                setParentEditModal(false)
                                                            }}>Cancel</Button>
                                                            <Button key="submit" style={{ marginLeft: "1rem", backgroundColor: "Orange", color: "white" }} onClick={handleParentSet}>Submit</Button>
                                                        </div>
                                                    </div>
                                                </Modal>
                                                <Modal
                                                    visible={childDeleteModal}
                                                    onCancel={() => {
                                                        setChildDeleteModal(false)
                                                        setSelectedSubCategory(null)
                                                    }}
                                                    footer={null}
                                                    width="500px"
                                                >
                                                    <div>
                                                        <p style={{ fontFamily: "DM Sans", fontWeight: 400, fontSize: "20px", color: "black" }}>Delete Category</p>
                                                        <hr />
                                                        <p style={{ fontSize: "18px", fontFamily: "DM Sans", fontWeight: 400, display: "flex", justifyContent: "center" }}>Are you sure you want to delete {selectedSubCategory?.set_name}?<br />This action cannot be undone.</p>
                                                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                                            <Button key="back" onClick={() => {
                                                                setSelectedSubCategory(null)
                                                                setChildDeleteModal(false)
                                                            }}>Cancel</Button>
                                                            <Button key="submit" style={{ marginLeft: "1rem", backgroundColor: "#FF4D4F", color: "white" }} onClick={() => handleChildCategoryDelete()}>Delete</Button>
                                                        </div>
                                                    </div>
                                                </Modal>
                                                <Drawer title="Add new sub category" placement="right" onClose={onClose} open={open} width={700} maskStyle={{ background: "rgba(255, 255, 255, 0.1)" }}
                                                    footer={
                                                        <div style={{ textAlign: "right", display: "flex", justifyContent: "space-between" }}>
                                                            <Button onClick={onClose} style={{ marginRight: 8 }}>
                                                                Cancel
                                                            </Button>
                                                            <Button onClick={async () => {
                                                                const selectedRowSkus = selectedRows.map((item) => item.sku)
                                                                if (selectedCategory) {
                                                                    console.log("Selected Rows", selectedRowSkus, selectedRows)
                                                                    if (selectedSubCategory) {
                                                                        const data = {
                                                                            set_name: selectedSubCategoryName,
                                                                            catalogId: selectedCatalog?.id,
                                                                            //productIds:selectedRowSkus,
                                                                            products: selectedRows,
                                                                            parentSetId: selectedCategory.id,
                                                                        }
                                                                        const config = {
                                                                            method: 'put', // Change to 'put' or 'patch' if updating an existing set
                                                                            url: `https://wc.adaptnxt.in/sets/${selectedSubCategory?.id}`, // Replace with your API endpoint URL
                                                                            headers: {
                                                                                'Content-Type': 'application/json',
                                                                                // Add any other headers as needed
                                                                            },
                                                                            data: data,
                                                                        };
                                                                        // Send the axios request
                                                                        await axios.request(config)
                                                                            .then((response) => {
                                                                                // Handle success, e.g., display a success message
                                                                                console.log('Set Updated successfully', response.data);
                                                                                setSelectedSubCategory(null)
                                                                                setSelecetedSubCategoryName('');
                                                                                setSelectedRows([]);
                                                                                setOpen(false)
                                                                                window.location.reload();
                                                                            })
                                                                            .catch((error) => {
                                                                                // Handle error, e.g., display an error message
                                                                                console.error('Error creating set', error);
                                                                            });
                                                                    } else {
                                                                        const data = {
                                                                            set_name: inputCategory,
                                                                            catalogId: selectedCatalog?.id,
                                                                            productIds: selectedRowSkus,
                                                                            parentSetId: selectedCategory.id, // Replace with the actual productIds or remove if not needed
                                                                        };

                                                                        console.log("Parenst set", data.parentSetId)
                                                                        // Define the axios configuration
                                                                        const config = {
                                                                            method: 'post', // Change to 'put' or 'patch' if updating an existing set
                                                                            url: 'https://wc.adaptnxt.in/sets', // Replace with your API endpoint URL
                                                                            headers: {
                                                                                'Content-Type': 'application/json',
                                                                                // Add any other headers as needed
                                                                            },
                                                                            data: data,
                                                                        };
                                                                        // Send the axios request
                                                                        axios.request(config)
                                                                            .then((response) => {
                                                                                // Handle success, e.g., display a success message
                                                                                console.log('Set created successfully', response.data);
                                                                                setSelectedSubCategory(null)
                                                                                setOpen(false)
                                                                                window.location.reload();
                                                                            })
                                                                            .catch((error) => {
                                                                                // Handle error, e.g., display an error message
                                                                                console.error('Error creating set', error);
                                                                            });
                                                                    }

                                                                }
                                                            }} style={{ backgroundColor: "#473355", color: "white", fontFamily: "DM Sans", fontSize: "14px", fontWeight: 400, width: "59px", height: "32px" }} disabled={!selectedRows} >
                                                                Add
                                                            </Button>
                                                        </div>
                                                    }
                                                >
                                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                                        <label style={{ fontFamily: "DM Sans", fontSize: "12px", fontWeight: 400 }}>Name</label>
                                                        <input
                                                            type="text"
                                                            placeholder="  Enter subcategory"
                                                            value={selectedSubCategoryName ? selectedSubCategoryName : inputCategory}
                                                            onChange={(e) => {
                                                                setSelecetedSubCategoryName(e.target.value)
                                                                setInputCategory(e.target.value)
                                                            }}
                                                            style={{ border: "1px solid #473355", width: "460px", height: "40px", borderRadius: "5px", background: "var(--neutral-1, #FFF)" }}
                                                        />
                                                    </div>

                                                    <p style={{ width: "400px", height: "22px", fontFamily: "DM Sans", fontSize: "14px", fontWeight: 400 }}>Choose the items you want and add sub categories</p>
                                                    <div>
                                                        <div style={{ width: "40%" }}>
                                                            <Input placeholder="Search product title here" onChange={(e) => {
                                                                setSearchProduct(e.target.value);
                                                                if (searchProduct !== '') {
                                                                    const filteredData = selectChatbot.filter((item) => item.title.toLowerCase().includes(e.target.value.toLowerCase()))

                                                                    // Remove duplicates if needed
                                                                    setFilterData(filteredData);
                                                                }
                                                            }} value={searchProduct} />
                                                        </div>
                                                        <Table

                                                            rowKey="id"
                                                            columns={subcolumns}
                                                            dataSource={selectChatbot}
                                                            rowSelection={rowSelection}
                                                            // expandable={{ expandedRowRender, defaultExpandedRowKeys: ['0'] }}
                                                            size='large'
                                                            scroll={{ y: 400 }}
                                                        />
                                                    </div>
                                                </Drawer>
                                            </div>
                                            {(expandedTab === tab.id && selectedCategory?.childSets && selectedCategory.childSets.length > 0) ? "" :
                                                <div style={{ display: "flex", justifyContent: "flex-start", marginLeft: "0.5rem" }}>
                                                    <p style={{ fontSize: '15px', fontWeight: 350, marginLeft: "2rem" }}>
                                                        {tab?.description}
                                                    </p>
                                                </div>
                                            }
                                            <div>
                                                {expandedTab === tab.id && selectedCategory?.childSets && selectedCategory.childSets.length > 0 && (
                                                    <div>
                                                        {selectedCategory.childSets.map((item: any) => {
                                                            return (
                                                                <>
                                                                    <div style={{ display: "flex", marginLeft: "2rem", border: "0.5px solid #473355", marginBottom: "1rem", width: "95%" }}>
                                                                        <div style={{ marginTop: "1.5rem", marginLeft: "1rem" }} onClick={() => toggleSubTabExpansion(item)}>
                                                                            {expandedSubTab === item.id ?<MinusCircleOutlined/>:<PlusCircleOutlined />}
                                                                        </div>
                                                                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                                                            <p style={{ fontSize: '18px', fontWeight: 500, marginLeft: "1rem" }}>
                                                                                {item.set_name}
                                                                            </p>
                                                                            <Dropdown menu={{
                                                                                items, onClick: ({ key }) => {
                                                                                    if (key === '1') handleChildSetEdit(item);
                                                                                    // else if (key === '2')handleCloseTask
                                                                                    if (key === '2') handleChildDeleteModal(item)
                                                                                }
                                                                            }} placement="bottomRight">
                                                                                <MoreOutlined style={{ cursor: "pointer" }} />
                                                                            </Dropdown>
                                                                        </div>
                                                                    </div>
                                                                    {expandedSubTab === item.id && (
                                                                        <div style={{ width: "95%", marginLeft: "2rem" }}>
                                                                            <Table

                                                                                rowKey="id"
                                                                                columns={columns}
                                                                                dataSource={productData}
                                                                                // expandable={{ expandedRowRender, defaultExpandedRowKeys: ['0'] }}
                                                                                size='middle'
                                                                                scroll={{ y: 200 }}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </>
                                                            )
                                                        })}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </>

                                );
                            }

                            // Return null or an empty fragment for cases where parentSet is null or undefined
                            return null;
                        })}

                </div>

            </div>
        </div>
    );
};

export default Categories;