import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Button, Form, Input, Modal, Table, TableColumnsType, Space, Dropdown, Select, Drawer, Descriptions } from 'antd';
import { PlusCircleOutlined, SyncOutlined } from '@ant-design/icons';
import axios from 'axios';
import { WhatsAppAccountInterface, CatalogInterface } from '../App';
import EditIcon from '../iconamoon_edit-thin.svg';
import DeleteIcon from '../DeleteOutlined.svg';
import GroupIcon from '../Group 4.svg';
import EyeIcon from '../ph_eye.svg';
import AvatarIcon from '../Avatar.svg';
import { Product } from './Products';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setCatalog, setToken, setWhatsapp } from '../actions';
import LoadingComponent from '../loadingComponent';


const cardStyle: React.CSSProperties = {
    width: '100%', // Set the width of the card
    margin: '0 auto', // Center the card horizontally
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Add a shadow
    borderRadius: '8px', // Rounded corners
    padding: '16px', // Add some padding inside the card
};

const rowStyle: React.CSSProperties = {
    display: 'flex',        // Use Flexbox
    flexDirection: 'row',  // Horizontal layout
    justifyContent: 'space-between', // Space between columns
    marginBottom: '16px',
    marginLeft: "1rem" // Add more space between rows
};

const textStyle: React.CSSProperties = {
    fontSize: '16px', // Set the font size
};

export interface Chatbot {
    id: number;
    chatbotName: string;
    whatsappAccountName: string;
    channelId: number;
    whatsappAccountId: number;
    business_Id: string;
    phoneNumberId: string;
    wabaId: string;
    metaAccessToken: string;
}



const WhatsappAccount: React.FC<any> = (props) => {

    const [isModalVisible, setIsModalVisible] = useState(false);
    const { whatsappAccount} = props;
    const [businessId, setBusinessId] = useState('');
    const [sellerInfo,setSellerInfo] = useState<any>();
    const [modalVisible, setModalVisible] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [channels, setChannels] = React.useState<any[]>([]);
    const [products, setProducts] = React.useState<Product[]>([]);
    const [isModalVisiblity, setIsModalVisiblity] = useState(false);
    const [catalog,setCatalog]=useState<any[]>();
    const [showProduct, setShowProduct] = useState(false);
    const [loading, setLoading] = React.useState(false);
    const [product, setProduct] = React.useState<any>();
    const [productDeleteModal, setProductDeleteModal] = useState(false);
    const [selectedChannel, setSelectedChannel] = useState<Array<{ label: string; value: number; avatar: string }>>([]);;
    const [initialFormValues, setInitialFormValues] = useState<Chatbot>();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const seller = useSelector((state:any)=>state.seller.seller);
    // const catalog = useSelector((state:any)=>state.catalog.catalog);
    async function getWhatsappAccount(seller: any) {
        try {
            const response = await axios.get(`https://wc.adaptnxt.in/whatsapp-accounts/seller/${seller.id}`);
            console.log("seller data", response);
            dispatch(setWhatsapp(response.data.whatsappAccounts))
            // setWhatsappAccount(response.data.whatsappAccounts)
        } catch (error) {
            console.log("seller data", error);
        }
    }

    const handleRefresh = async (value: any) => {
        setLoading(true);
        const catalogs = await axios.get(`https://wc.adaptnxt.in/catalog/${value.seller.id}/getCatalog`)
        console.log("Catalog Data", catalogs)
        const catalogsData = catalogs.data;
        const catalogData: CatalogInterface[] = catalogsData.map((item: any) => ({
            id: item.id,
            name: item.name,
            meta_catalog_id: item.meta_catalog_id,
            sellerId: item.seller ? item.seller.id : null, // Assign sellerId if seller exists, otherwise null
            whatsappAccount: item.whatsappAccount ? item.whatsappAccount : null, // Assign whatsapp_account_name if whatsappAccount exists, otherwise null
            products: item.products ? item.products : null
        }));
        const filteredCatalogData = catalogData.filter((item) => value.seller.id === item.sellerId);
        console.log("Filter Catalog Data", filteredCatalogData)
       setCatalog(catalogsData);
        //setProducts(filteredCatalogData.data[0].products);
        setLoading(false);
    }

    async function getChannels(seller: any) {
        try {
            let data = JSON.stringify({
                "filters": {
                    "channel_id": null,
                    "sku": null,
                    "title": null,
                    "search": ""
                }
            });

            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: 'https://api.pnxt.in/api/v1/auth/mcm/channel/all',
                headers: {
                    'sec-ch-ua': '"Chromium";v="116", "Not)A;Brand";v="24", "Google Chrome";v="116"',
                    'sec-ch-ua-mobile': '?0',
                    'Authorization': `Bearer ${seller.token}`,
                    'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/116.0.0.0 Safari/537.36',
                    'Content-Type': 'application/json',
                    'Accept': 'application/json, text/plain, */*',
                    'Referer': 'https://demo.pointnxt.com/',
                    'sec-ch-ua-platform': '"Windows"'
                },
                data: data
            };
            dispatch(setToken(seller.token));

            const channels = await axios.request(config);
            if (channels.status === 401) {
                navigate('/login');
            } else if (channels.status === 200) {
                const channelNames = channels.data.result.map((item: any) =>
                    ({ label: item.name, value: item.id, avatar: item.avatar }));
                setChannels(channelNames);
            }
        } catch (error) {
            console.log("seller data", error);
        }
    }
    const handleDeleteModal = async (record: any) => {
        setProduct(record)
        setProductDeleteModal(true)
    }
    const handleShowProduct = async (record: any) => {
        console.log("Show Product", record)
        setProduct(record)
        setShowProduct(true)
    }

    const subcolumns: TableColumnsType<Product> = [
        { title: 'Product Name', dataIndex: 'title', key: 'title', sorter: (a, b) => a.title.length - b.title.length, },
        { title: 'SKU', dataIndex: 'sku', key: 'sku', sorter: (a, b) => a.sku.length - b.sku.length, },
        { title: 'Price', dataIndex: 'price', key: 'price', sorter: (a, b) => a.price - b.price, },
        { title: 'Stock in hand', dataIndex: 'stock', key: 'stock', sorter: (a, b) => a.stock - b.stock, },
        {
            title: 'Action',
            dataIndex: 'operation',
            key: 'operation',
            render: (text, record) => (
                <div>
                    <a onClick={() => handleDeleteModal(record)}><img src={DeleteIcon} alt="Delete" style={{ cursor: "pointer", marginRight: "10px" }} className="edit-icon" /></a>
                    {product && (<Modal
                        visible={productDeleteModal}
                        onCancel={() => {
                            setProduct(null)
                            setProductDeleteModal(false)
                        }}
                        maskStyle={{ background: "rgba(255, 255, 255, 0.1)" }}
                        footer={null}
                        width="670px"
                        bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
                    >
                        <div>
                            <p style={{ fontFamily: "DM Sans", fontWeight: 400, fontSize: "20px", color: "black" }}>Delete Product</p>
                            <hr />
                            <p style={{ fontSize: "18px", fontFamily: "DM Sans", fontWeight: 400, display: "flex", justifyContent: "center" }}>Are you sure you want to delete {product.title}?<br />This action cannot be undone.</p>
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Button key="back" onClick={() => {
                                    setProduct(null)
                                    setProductDeleteModal(false)
                                }}>Cancel</Button>
                                <Button key="submit" style={{ marginLeft: "1rem", backgroundColor: "#FF4D4F", color: "white" }} onClick={() => handleProductDelete(product)}>Delete</Button>
                            </div>
                        </div>
                    </Modal>)}
                    <a onClick={() => handleShowProduct(record)}><img src={EyeIcon} alt="Edit" className="edit-icon" /></a>
                    <Modal
                        visible={showProduct}
                        onCancel={() => setShowProduct(false)}
                        footer={null}
                        width="65%"
                        maskStyle={{ background: "rgba(255, 255, 255, 0.1)" }}
                        bodyStyle={{ maxHeight: 'max-content', overflow: 'auto' }}
                    >
                        <Row style={{ display: 'flex', justifyContent: 'center' }}>
                            <Card
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                    margin: '10px',
                                    borderRadius: '12px',
                                    minWidth: '60%',
                                    minHeight: '500px',
                                    height: '100%',
                                    boxShadow: '10px 10px 4px 0px rgba(128, 128, 128, 0.10)',
                                }}
                            >
                                <Row gutter={24} style={{ justifyContent: 'center', display: 'flex' }}>
                                    <Col style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                                        <img
                                            src={
                                                product?.image_url === null
                                                    ? 'https://static.vecteezy.com/system/resources/previews/005/337/799/original/icon-image-not-found-free-vector.jpg'
                                                    : product?.image_url
                                            }
                                            width={220}
                                            height={320}
                                            style={{
                                                borderRadius: '12px',
                                                marginLeft: '15px',
                                                boxShadow: '5px 5px 4px 0px rgba(128, 128, 128, 0.10)',
                                            }}
                                            alt="Product Image"
                                        />
                                    </Col>
                                    <Col style={{ paddingLeft: '30px' }}>
                                        <p style={{ fontSize: '30px', fontWeight: '400' }}>{product?.title}</p>
                                        <div style={{ width: '750px' }}>
                                            <p
                                                style={{
                                                    fontSize: '15px',
                                                    display: '-webkit-box',
                                                    WebkitLineClamp: 4,
                                                    WebkitBoxOrient: 'vertical',
                                                    overflow: 'hidden',
                                                }}
                                            >
                                                {product?.description}
                                            </p>
                                        </div>
                                        <Descriptions bordered>
                                            <Descriptions.Item label="Price">{product?.price}</Descriptions.Item>
                                            <Descriptions.Item label="Stock In Hand">{product?.stock}</Descriptions.Item>
                                            <Descriptions.Item label="Product SKU">{product?.sku}</Descriptions.Item>
                                        </Descriptions>
                                    </Col>
                                </Row>
                            </Card>
                        </Row>
                    </Modal>
                </div>

            )
        }
        // { title: 'Weight', dataIndex: 'weight', key: 'weight', sorter: (a, b) => a.weight - b.weight, },
        // { title: 'Length', dataIndex: 'dimension_length', key: 'dimension_length', sorter: (a, b) => a.dimension_length - b.dimension_length, },
        // { title: 'Breadth', dataIndex: 'dimension_breadth', key: 'dimension_breadth', sorter: (a, b) => a.dimension_breadth - b.dimension_breadth },
        // { title: 'Height', dataIndex: 'dimension_height', key: 'dimension_height', sorter: (a, b) => a.dimension_height - b.dimension_height },
        // { title: 'HS Code', dataIndex: 'hscode', key: 'hscode', sorter: (a, b) => a.hscode.length - b.hscode.length },
    ];
    const handleProductDelete = async (record: any) => {
        console.log("delete Product", record);
        const product = await axios.delete(`https://wc.adaptnxt.in/products/${record.id}`)
        setLoading(true);
        window.location.reload();
    }
    async function getCatalog(seller: any) {
        try {
            const response = await axios.get(`https://wc.adaptnxt.in/whatsapp-accounts/seller/${seller.id}`);
            console.log("seller data", response);
            setSellerInfo(response.data);
            setBusinessId(response.data.business_id);

            const catalogs = await axios.get(`https://wc.adaptnxt.in/catalog/${seller.id}/getCatalog`)
            console.log("Catalog Data", catalogs)
            const catalogsData = catalogs.data;
            const catalogData: CatalogInterface[] = catalogsData.map((item: any) => ({
                id: item.id,
                name: item.name,
                meta_catalog_id: item.meta_catalog_id,
                sellerId: item.seller ? item.seller.id : null, // Assign sellerId if seller exists, otherwise null
                whatsappAccount: item.whatsappAccount ? item.whatsappAccount : null, // Assign whatsapp_account_name if whatsappAccount exists, otherwise null
                products: item.products ? item.products : null
            }));
            const filteredCatalogData = catalogData.filter((item) => item.sellerId === seller.id);
            console.log("Filter Catalog Data", filteredCatalogData)
            setCatalog(catalogsData)
            // if (newWhatsAppAccounts.length > 0) {
            //     const res = await axios.get(`http://localhost:3002/catalog/${newWhatsAppAccounts[0].id}`)
            //     console.log("Catalog response",res);
            //     setCatalog(res.data)
            // }
        } catch (error) {
            console.log("seller data", error);
        }
    }
    useEffect(() => {
        console.log("Business Id", businessId)
        console.log("Seller",seller);
        const sellerJSON = localStorage.getItem('seller');
        // async function fetchData() {
        //     const response = await getSellerData(sellerData);
        //     setSeller(response);
        // }
        // fetchData();
        if (sellerJSON) {
            const sellerData = JSON.parse(sellerJSON)
            getCatalog(sellerData);
            getWhatsappAccount(sellerData);
            getChannels(sellerData)
        }

    }, []);


    const onFinish = async (values: any) => {
        // Access username and password from the values object
        const { phoneNumberId, wabaId, metaAccessToken, whatsappAccountName, business_id, chatbotName, channelId } = values;
        console.log("Whatsapp account name",whatsappAccountName);
        try {
            if (initialFormValues) {
                setIsModalVisiblity(false);
                setLoading(true);
                const sellerJSON = localStorage.getItem('seller');
                // async function fetchData() {
                //     const response = await getSellerData(sellerData);
                //     setSeller(response);
                // }
                // fetchData();
                if (sellerJSON) {
                    const seller = JSON.parse(sellerJSON)
                    console.log("Inside edit chatbot")
                    const response = await axios.put(`https://wc.adaptnxt.in/catalog/${initialFormValues.id}`, {
                        name: chatbotName,
                        whatsapp_name: whatsappAccountName,
                        channelId: channelId,
                        business_id: parseInt(business_id, 10),
                        sellerId: sellerInfo.id
                    }, {
                        headers: {
                            'Accept': '*/*',
                            'Content-Type': 'application/json',
                        },
                    })
                    setIsModalVisiblity(false);
                    console.log("channels", response)
                    const selectedchannel = channels.filter((item: any) => item.value === channelId);
                    console.log("Updated Channel", selectedchannel)
                    setSelectedChannel(selectedChannel)
                    // setBusinessId(business_id)
                    getCatalog(sellerInfo)
                    setLoading(false);
                    window.location.reload()
                }
            } else {
                setIsModalVisible(false);
                setLoading(true);
                const sellerJSON = localStorage.getItem('seller');
                // async function fetchData() {
                //     const response = await getSellerData(sellerData);
                //     setSeller(response);
                // }
                // fetchData();
                if (sellerJSON) {
                    const seller = JSON.parse(sellerJSON)
                    console.log("CReate chatbot seller",seller);
                    setBusinessId(business_id)
                    const response = await axios.post(`https://wc.adaptnxt.in/whatsapp-accounts/${sellerInfo.id}`, {
                        whatsapp_name: whatsappAccountName,
                        phone_number_id: parseInt(phoneNumberId, 10),
                        waba_id: parseInt(wabaId, 10),
                        meta_access_token: metaAccessToken.toString(),
                        sellerId: sellerInfo.id
                    }, {
                        headers: {
                            'Accept': '*/*',
                            'Content-Type': 'application/json',
                        },
                    });

                    if (response.status === 200) {
                        // Navigate to the dashboard page
                        console.log("Whatsapp account", response.data)
                        setIsModalVisible(false);
                        dispatch(setWhatsapp((prevWhatsappAccount: any) => [
                            ...prevWhatsappAccount,
                            response.data,
                        ]))
                        // setWhatsappAccount((prevWhatsappAccount: any) => [
                        //     ...prevWhatsappAccount,
                        //     response.data,
                        // ]); // Assuming the data you want to set is in response.data
                        await axios.patch(`https://wc.adaptnxt.in/${seller.id}`, {
                            business_id: business_id
                        }, {
                            headers: {
                                'Accept': '*/*',
                                'Content-Type': 'application/json',
                            },
                        })
                    } else {
                        // Handle other status codes or errors here
                        console.error('Failed to create WhatsApp account');
                    }
                    console.log("Whatsapp Name", whatsappAccountName)
                    const res = await axios.post(`https://wc.adaptnxt.in/catalog`, {
                        name: chatbotName,
                        whatsapp_name: whatsappAccountName,
                        channelId: channelId,
                        business_id: parseInt(business_id, 10),
                        sellerId: seller.id
                    }, {
                        headers: {
                            'Accept': '*/*',
                            'Content-Type': 'application/json',
                        },
                    });
                    if (res.status === 200) {
                        // Navigate to the dashboard page
                        console.log("Catalog Data", res.data)
                        setIsModalVisible(false);
                        const catalogData = {
                            id: res.data.id,
                            name: res.data.name,
                            meta_catalog_id: res.data.meta_catalog_id,
                            sellerId: res.data.seller ? res.data.seller.id : null,
                            whatsappAccount: res.data.whatsappAccount ? res.data.whatsappAccount : null,
                            products: res.data.products ? res.data.products : null,
                        };
                        setCatalog((prevCatalog: any) => [
                            ...prevCatalog,
                            catalogData,
                        ]);
                         setLoading(false);
                       window.location.reload();
                        // Assuming the data you want to set is in response.data
                    } else {
                        // Handle other status codes or errors here
                        console.error('Failed to create WhatsApp account');
                    }
                }
            }

        } catch (error) {
            // Handle network errors or other exceptions
            console.error('Error creating WhatsApp account:', error);
        }

    };

    const showModal = () => {
        // setInitialFormValues({});
        setIsModalVisible(true);

    };

    const showEditModal = (record: any) => {
        // Set initial values when editing a record
        console.log("record", record)
        const initialValues = {
            id: record.id,
            chatbotName: record.name,
            whatsappAccountName: record.whatsappAccount.whatsapp_name,
            channelId: record.channelId,
            whatsappAccountId: record.whatsappAccount.id,
            business_Id: businessId,
            phoneNumberId: record.whatsappAccount.phone_number_id,
            wabaId: record.whatsappAccount.waba_id,
            metaAccessToken: record.whatsappAccount.meta_access_token,
        };

        // Set initialFormValues first
        setInitialFormValues(initialValues);
        console.log("Intial values", initialValues)
        setTimeout(() => {
            setIsModalVisiblity(true)
        }, 500);
    };

    const showDeleteModal = async (item: any) => {
        console.log("Delete Modal", item)
        setModalVisible(true)
    }

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const handleCancels = () => {
        setIsModalVisiblity(false);
    };

    const handleCatalogDelete = async (item: any) => {
        setModalVisible(false);
        setLoading(true);
        console.log("Delete Catalog", item)
        const response = await axios.delete(`https://wc.adaptnxt.in/catalog/${item.id}`);
        console.log("")
        const res = await axios.delete(`https://wc.adaptnxt.in/whatsapp-accounts/${item.whatsappAccount.id}`)
        setLoading(false);
        window.location.reload();
    }
    const modalcolumns: TableColumnsType<WhatsAppAccountInterface> = [
        {
            title: 'ID', dataIndex: 'id', key: 'id',
        },
        { title: 'Whatsapp Account', dataIndex: 'whatsapp_name', key: 'whatsapp_name', sorter: (a, b) => a.whatsapp_name.length - b.whatsapp_name.length, },
        { title: 'Phone Number Id', dataIndex: 'phone_number_id', key: 'phone_number_id', sorter: (a, b) => a.phone_number_id - b.phone_number_id, },
        { title: 'Waba Id', dataIndex: 'waba_id', key: 'waba_id', sorter: (a, b) => a.waba_id - b.waba_id },
        // {
        //     title: 'Access Token',
        //     dataIndex: 'meta_access_token',
        //     key: 'meta_access_token',
        //     sorter: (a, b) => a.meta_access_token.length - b.meta_access_token.length
        // },
        {
            title: 'Action',
            dataIndex: 'operation',
            key: 'operation',
            render: () => (
                <Space size="small" className='table-action'>
                    <a>Pause</a>
                    <a>Stop</a>
                </Space>
            ),
        },

    ];

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '1rem' }}>
            {(!whatsappAccount) ? (
                <>
                    <PlusCircleOutlined style={{ fontSize: '50px', color: '#7209B7' }} onClick={showModal} />
                    <p style={{ fontSize: "18px", fontFamily: 'DM Sans' }}>Add WhatsApp Account</p>
                </>
            ) : (
                <>
                    <div style={{ width: "100%", marginLeft: "2rem" }}>
                        <div style={{ display: "flex", justifyContent: "flex-start", marginBottom: "1rem" }}>
                            <Button style={{ color: "white", fontFamily: "DM Sans", fontWeight: 400, fontSize: "14px", backgroundColor: "#473355" }} onClick={showModal}>Create chatbot</Button>
                        </div>
                        <div style={{ display: "flex" }}>
                            {!catalog || loading?<div style={{marginLeft:"30rem"}}><LoadingComponent/></div>:catalog?.map((item: any) => (
                                <div key={item.id}>
                                    <div style={{ width: "500px", height: "358px", boxShadow: "8px 8px 4px 0px rgba(246, 248, 251, 0.50)", borderRadius: "5px" }}>
                                        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }} className="box-header">
                                            <div style={{ display: "flex", alignItems: "center" }} className="box-icons">
                                                <img src={EditIcon} alt="Edit" style={{ cursor: "pointer", marginRight: "10px" }} onClick={() => { showEditModal(item) }} className="edit-icon" />
                                                <div>
                                                    <img src={DeleteIcon} alt="Delete" style={{ cursor: "pointer", marginRight: "10px" }} onClick={() => showDeleteModal(item)} className="edit-icon" />
                                                    <Modal
                                                        visible={modalVisible}
                                                        onCancel={() => setModalVisible(false)}
                                                        footer={null}
                                                        width="670px"
                                                        bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
                                                    >
                                                        <p style={{ fontFamily: "DM Sans", fontWeight: 400, fontSize: "20px", color: "black" }}>Delete {item.name}</p>
                                                        <hr />
                                                        <p style={{ fontSize: "18px", fontFamily: "DM Sans", fontWeight: 400, display: "flex", justifyContent: "center" }}>Are you sure you want to delete {item.name} chatbot?<br />This action cannot be undone.</p>
                                                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                                            <Button key="back" onClick={handleCancel} onClickCapture={() => setModalVisible(false)}>Cancel</Button>
                                                            <Button key="submit" style={{ marginLeft: "1rem", backgroundColor: "#FF4D4F", color: "white" }} onClick={() => handleCatalogDelete(item)}>Delete</Button>
                                                        </div>
                                                    </Modal>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ display: "flex" }} className="box-content">
                                            <div style={{ flex: 1, padding: "10px" }} className="content-left">
                                                <img src={GroupIcon} alt="Edit" className="edit-icon" />
                                            </div>
                                            <div style={{ flex: 1, padding: "10px" }} className="content-right">
                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <input type="text" id="field1" name="Chatbot Name" style={{ border: "none", height: "22px", fontFamily: "DM Sans", fontWeight: 400, fontSize: "20px", width: '100%' }} value={item.name} />
                                                    </div>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <input type="text" id="field1" name="App Name" style={{ border: "none", height: "22px", fontFamily: "DM Sans", fontWeight: 400, fontSize: "16px", width: `300px` }} value={item.whatsappAccount.whatsapp_name} />
                                                    </div>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <span style={{ fontFamily: "DM Sans", fontWeight: 500, fontSize: "16px", width: "100px" }}>Business Id:</span>
                                                        <input type="text" id="field1" name="App Name" style={{ border: "none", height: "22px", fontFamily: "DM Sans", fontWeight: 400, fontSize: "16px", }} value={businessId} />
                                                    </div>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <span style={{ fontFamily: "DM Sans", fontWeight: 500, fontSize: "16px", width: "auto" }}>Waba Id:</span>
                                                        <input type="text" id="field1" name="App Name" style={{ border: "none", height: "22px", fontFamily: "DM Sans", fontWeight: 400, fontSize: "16px", width: `${businessId.length * 10}px` }} value={item.whatsappAccount.waba_id} />
                                                    </div>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <span style={{ fontFamily: "DM Sans", fontWeight: 500, fontSize: "16px", width: "auto" }}>Phone No. Id:</span>
                                                        <input type="text" id="field1" name="App Name" style={{ border: "none", height: "22px", fontFamily: "DM Sans", fontWeight: 400, fontSize: "16px", width: `${businessId.length * 10}px` }} value={item.whatsappAccount.phone_number_id} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }} className="box-tabs">
                                            {/* Tabs go here */}
                                            <div style={{ padding: "10px", boxShadow: "8px 8px 4px 0px rgba(246, 248, 251, 0.50)", flex: 1, textAlign: "center", cursor: "pointer", fontFamily: "DM Sans", fontSize: "14px", fontWeight: 400, marginRight: "1rem" }} className="tab">Total Products</div>
                                            <div style={{ display: "flex", justifyContent: "space-between", padding: "10px", boxShadow: "8px 8px 4px 0px rgba(246, 248, 251, 0.50)", flex: 1, textAlign: "center", cursor: "pointer", marginLeft: "-1rem" }} className="tab">{item.products ? item.products.length : '0'}  <img src={EyeIcon} alt="Edit" onClick={() => {
                                                console.log("Chatbot Products", item.products)
                                                setProducts(item.products)
                                                setOpen(true)
                                            }} className="edit-icon" /></div>
                                            <Drawer title="Catalog Products" placement="right" onClose={() => setOpen(false)} open={open} width={700}>
                                                <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "0.5rem" }}>
                                                    <Button style={{ backgroundColor: "#473355", color: "white" }} onClick={() => handleRefresh(item)} loading={loading} >
                                                        <SyncOutlined /> Refresh
                                                    </Button>
                                                </div>
                                                <Table
                                                    rowKey="id"
                                                    columns={subcolumns}
                                                    dataSource={products}
                                                    size='large'
                                                    scroll={{ y: 600 }}
                                                    loading={loading}
                                                />
                                            </Drawer>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "space-between" }} className="box-tabs">
                                            <div style={{ padding: "10px", boxShadow: "8px 8px 4px 0px rgba(246, 248, 251, 0.50)", flex: 1, textAlign: "center", cursor: "pointer", fontFamily: "DM Sans", fontSize: "14px", fontWeight: 400, paddingLeft: "1rem" }} className="tab">Total Categories</div>
                                            <div style={{ display: "flex", justifyContent: "space-between", padding: "10px", boxShadow: "8px 8px 4px 0px rgba(246, 248, 251, 0.50)", flex: 1, textAlign: "center", cursor: "pointer", paddingRight: "1rem" }} className="tab">{item.sets ? item.sets.length : '0'}</div>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "space-between" }} className="box-tabs">
                                            {/* Tabs go here */}
                                            <div style={{ padding: "10px", boxShadow: "8px 8px 4px 0px rgba(246, 248, 251, 0.50)", flex: 1, textAlign: "center", cursor: "pointer", fontFamily: "DM Sans", fontSize: "14px", fontWeight: 400, marginRight: "0.6rem" }} className="tab">Total Orders</div>
                                            <div style={{ display: "flex", padding: "10px", boxShadow: "8px 8px 4px 0px rgba(246, 248, 251, 0.50)", flex: 1, textAlign: "center", cursor: "pointer", paddingRight: "1.5rem" }} className="tab">{item.orders ? item.orders.length : '0'}</div>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "space-between", borderRadius: "5px" }} className="box-tabs">
                                            <div style={{ padding: "10px", boxShadow: "8px 8px 4px 0px rgba(246, 248, 251, 0.50)", textAlign: "center", cursor: "pointer", fontFamily: "DM Sans", fontSize: "14px", fontWeight: 400, paddingLeft: "5rem", flex: 1, marginRight: "1rem" }} className="tab">Channel</div>
                                            {channels.map((channel) => (
                                                <div key={channel.value}>
                                                    {channel.value === item.channelId ? (
                                                        <div key={channel.value} style={{ display: "flex", paddingTop: "10px", boxShadow: "8px 8px 4px 0px rgba(246, 248, 251, 0.50)", textAlign: "center", flex: 1, cursor: "pointer", width: "10rem" }} className="tab">
                                                            <img style={{ height: "20px", width: "20px", marginRight: "0.5rem" }} src={channel.avatar} alt="Channel Avatar" className="channel-avatar" />
                                                            <div>{channel.label}</div>
                                                        </div>
                                                    ) : (
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                padding: "10px",
                                                                boxShadow: "8px 8px 4px 0px rgba(246, 248, 251, 0.50)",
                                                                flex: 1,
                                                                marginRight: "2rem",
                                                                textAlign: "center",
                                                                cursor: "pointer",
                                                            }}
                                                            className="tab"
                                                        ></div>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {/* Additional content */}
                    </div>
                </>
            )}

            {/* Modal for creating WhatsApp Account */}
            <Modal
                visible={isModalVisiblity}
                onCancel={handleCancels}
                footer={null}
                width="700px"
            >
                <p style={{ fontFamily: "DM Sans", fontWeight: 400, fontSize: "20px", color: "black" }}>Edit Chatbot</p>
                <hr />
                <Form name="create-account-form" onFinish={onFinish} initialValues={initialFormValues} >
                    <Form.Item
                        label="App Name"
                        name="whatsappAccountName"
                        rules={[{ message: 'Please input Whatsapp Account Name!' }]}
                    >
                        <Input style={{ width: "394px", height: "30px", marginLeft: "1.5rem" }} />
                    </Form.Item>
                    <Form.Item
                        label="Chatbot Name"
                        name="chatbotName"
                        rules={[{ message: 'Please input Whatsapp Account Name!' }]}
                    >
                        <Input style={{ width: "394px", height: "30px" }} />
                    </Form.Item>
                    <Form.Item
                        label="Select channel"
                        name="channelId"
                    >
                        <Select
                            // mode="multiple"
                            disabled
                            allowClear
                            style={{ width: '394px' }}
                            placeholder="Select your channel"
                            // defaultValue={['a10', 'c12']}
                            // onChange={handleSelectChange}
                            options={channels}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Business Id"
                        name="business_id"
                        rules={[{ message: 'Please input Business Id!' }]}
                    >
                        <Input style={{ width: "394px", height: "30px", marginLeft: "1.5rem" }} />
                    </Form.Item>
                    <Form.Item
                        label="Phone Number Id"
                        name="phoneNumberId"
                        rules={[{ message: 'Please input Phone Number ID!' }]}
                    >
                        <Input style={{ width: "394px", height: "30px" }} />
                    </Form.Item>
                    <Form.Item
                        label="Waba Id"
                        name="wabaId"
                        rules={[{ message: 'Please input WaBA ID!' }]}
                    >
                        <Input style={{ width: "394px", height: "30px", marginLeft: "2.8rem" }} />
                    </Form.Item>
                    <Form.Item
                        label="Meta Access Token"
                        name="metaAccessToken"
                        rules={[{ message: 'Please input Meta Access Token!' }]}
                    >
                        <Input style={{ width: "394px", height: "30px" }} />
                    </Form.Item>
                    <Form.Item>
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button onClick={() => setIsModalVisiblity(false)}>
                                Cancel
                            </Button>
                            <Button style={{ backgroundColor: '#473355', color: 'white', marginLeft: "1rem" }} htmlType="submit">
                                Update
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                width="700px"
            >
                <p style={{ fontFamily: "DM Sans", fontWeight: 400, fontSize: "20px", color: "black" }}>Create Chatbot</p>
                <hr />
                <Form name="create-account-form" onFinish={onFinish}  >
                    <Form.Item
                        label="App Name"
                        name="whatsappAccountName"
                        rules={[{ message: 'Please input Whatsapp Account Name!' }]}
                        style={{ fontFamily: "DM Sans", fontWeight: 400, }}
                    >
                        <Input style={{ width: "394px", height: "30px", marginLeft: "1.5rem" }} />
                    </Form.Item>
                    <Form.Item
                        label="Chatbot Name"
                        name="chatbotName"
                        rules={[{ message: 'Please input Whatsapp Account Name!' }]}
                        style={{ fontFamily: "DM Sans", fontWeight: 400, }}
                    >
                        <Input style={{ width: "394px", height: "30px" }} />
                    </Form.Item>
                    <Form.Item
                        label="Select channel"
                        name="channelId"
                    >
                        <Select
                            // mode="multiple"
                            allowClear
                            style={{ width: '394px' }}
                            placeholder="Select your channel"
                            // defaultValue={['a10', 'c12']}
                            // onChange={handleSelectChange}
                            options={channels}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Business Id"
                        name="business_id"
                        rules={[{ message: 'Please input Business Id!' }]}
                    >
                        <Input style={{ width: "394px", height: "30px", marginLeft: "1.5rem" }} />
                    </Form.Item>
                    <Form.Item
                        label="Phone Number Id"
                        name="phoneNumberId"
                        rules={[{ message: 'Please input Phone Number ID!' }]}
                    >
                        <Input style={{ width: "394px", height: "30px" }} />
                    </Form.Item>
                    <Form.Item
                        label="Waba Id"
                        name="wabaId"
                        rules={[{ message: 'Please input WaBA ID!' }]}
                    >
                        <Input style={{ width: "394px", height: "30px", marginLeft: "2.8rem" }} />
                    </Form.Item>
                    <Form.Item
                        label="Meta Access Token"
                        name="metaAccessToken"
                        rules={[{ message: 'Please input Meta Access Token!' }]}
                    >
                        <Input style={{ width: "394px", height: "30px" }} />
                    </Form.Item>
                    <Form.Item>
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button onClick={() => setIsModalVisiblity(false)}>
                                Cancel
                            </Button>
                            <Button style={{ backgroundColor: '#473355', color: 'white', marginLeft: "1rem" }} htmlType="submit">
                                Create
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );

};

export default WhatsappAccount;
