import { combineReducers } from 'redux';
import { SET_TOKEN } from './actions';
import { SET_SELLER_DATA } from './actions';
import {SET_SELLER} from './actions';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

interface AuthState {
  token: string | null;
}

interface SellerState {
    seller: any;
  }

interface SellerDataState {
    sellerData: any;
}

interface CategoriesState {
  categories: any;
}

interface WhatsappState {
  whatsapp: any;
}

interface WhatsappCatalogState{
  whatsappCatalog:any;
}

interface CatalogState{
  catalog:any;
}

const initialAuthState: AuthState = {
  token: null,
};

const initialSellerDataState: SellerDataState = {
    sellerData: null,
};

const initialSellerState: SellerState = {
    seller: null,
};

const initialCategoriesState: CategoriesState = {
  categories: null,
};

const initialWhatsappState: WhatsappState = {
  whatsapp: null,
};

const initialWhatsappCatalogState: WhatsappCatalogState = {
  whatsappCatalog: null,
};

const initialCatalogState: CatalogState = {
  catalog: null,
};



const authReducer = (state: AuthState = initialAuthState, action: any) => {
  switch (action.type) {
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    default:
      return state;
  }
};

const sellerDataReducer = (state = initialSellerDataState, action: any) => {
    switch (action.type) {
        case SET_SELLER_DATA:
            return {
                ...state,
                sellerData: action.payload,
            };
        default:
            return state;
    }
};

const sellerReducer = (state = initialSellerState, action: any) => {
  console.log('Old seller state:', state.seller);
  console.log('New seller state:', action.payload);
    switch (action.type) {
        case SET_SELLER:
            return {
                ...state,
                seller: action.payload,
            };
        default:
            return state;
    }
};

const categoriesReducer = (state = initialCategoriesState, action:any) => {
  switch (action.type) {
    case 'SET_CATEGORIES':
      return {
        ...state,
        categories: action.payload,
      };
    default:
      return state;
  }
};

const whatsappReducer = (state = initialWhatsappState,action:any)=>{
  switch (action.type) {
    case 'SET_WHATSAPP':
      return {
        ...state,
        whatsapp: action.payload,
      };
    default:
      return state;
  }
} 

const whatsappCatalogReducer = (state = initialWhatsappCatalogState,action:any)=>{
  switch (action.type) {
    case 'SET_WHATSAPP_CATALOG':
      return {
        ...state,
        whatsappCatalog: action.payload,
      };
    default:
      return state;
  }
} 
const catalogReducer = (state = initialCatalogState,action:any)=>{
  switch (action.type) {
    case 'SET_CATALOG':
      return {
        ...state,
        catalog: action.payload,
      };
    default:
      return state;
  }
}

const rootReducer = combineReducers({
  auth: authReducer,
  sellerData: sellerDataReducer,
  seller: sellerReducer,
  categories: categoriesReducer,
  whatsapp: whatsappReducer,
  whatsappCatalog: whatsappCatalogReducer,
  catalog: catalogReducer, 
});

const persistConfig = {
  key: 'root',
  storage,
};

// Create persisted reducer
const persistedReducer = persistReducer<RootState>(persistConfig, rootReducer);

export default persistedReducer;


export type RootState = ReturnType<typeof rootReducer>;
