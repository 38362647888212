import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Button, Form, Input, Modal, Table, TableColumnsType, Space, Dropdown } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import { CatalogInterface, WhatsAppAccountInterface } from '../App';

const cardStyle: React.CSSProperties = {
    width: '100%', // Set the width of the card
    margin: '0 auto', // Center the card horizontally
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Add a shadow
    borderRadius: '8px', // Rounded corners
    padding: '16px', // Add some padding inside the card
};

const rowStyle: React.CSSProperties = {
    display: 'flex',        // Use Flexbox
    flexDirection: 'row',  // Horizontal layout
    justifyContent: 'space-between', // Space between columns
    marginBottom: '16px',
    marginLeft: "1rem" // Add more space between rows
};

const textStyle: React.CSSProperties = {
    fontSize: '16px', // Set the font size
};


const Catalog: React.FC<any> = (props) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { seller, whatsappAccount, setWhatsappAccount, setSeller, catalog, setCatalog } = props;
    const [businessId, setBusinessId] = useState('');
    const [whatsappName, setWhatsappName] = useState('');
    const [catalogName, setCatalogName] = useState('');
    useEffect(() => {
        // async function fetchData() {
        //     const response = await getSellerData(sellerData);
        //     setSeller(response);
        // }
        // fetchData();
        async function getWhatsappAccount() {
            try {
                const response = await axios.get(`https://wc.adaptnxt.in/whatsapp-accounts/seller/${seller.data.id}`);
                console.log("seller data", response);
                setWhatsappAccount(response.data.whatsappAccounts)
            } catch (error) {
                console.log("seller data", error);
            }


        }
        getWhatsappAccount();

        async function getCatalog() {
            try {
                const response = await axios.get(`https://wc.adaptnxt.in/whatsapp-accounts/seller/${seller.data.id}`);
                console.log("seller data", response);
                setBusinessId(response.data.business_id)
                const newWhatsAppAccounts = response.data.whatsappAccounts.filter((item: any) => {
                    return !whatsappAccount.some((existingItem: any) => existingItem.whatsapp_name === whatsappName);
                });
              
                const catalogs = await axios.get(`https://wc.adaptnxt.in/catalog/${seller.data.id}/getCatalog`)
                const catalogsData = catalogs.data;
                const catalogData: CatalogInterface[] = catalogsData.map((item:any)=> ({
                    id: item.id,
                    name: item.name,
                    meta_catalog_id: item.meta_catalog_id,
                    sellerId: item.seller ? item.seller.id : null, // Assign sellerId if seller exists, otherwise null
                    whatsappAccount: item.whatsappAccount ? item.whatsappAccount.whatsapp_name : null, // Assign whatsapp_account_name if whatsappAccount exists, otherwise null
                  }));
                  const filteredCatalogData = catalogData.filter((item) => item.sellerId === seller.data.id);
                setCatalog(filteredCatalogData)
                // if (newWhatsAppAccounts.length > 0) {
                //     const res = await axios.get(`http://localhost:3002/catalog/${newWhatsAppAccounts[0].id}`)
                //     console.log("Catalog response",res);
                //     setCatalog(res.data)
                // }
            } catch (error) {
                console.log("seller data", error);
            }
        }
        getCatalog();
    }, []);

    const onFinish = async (values: any) => {
        // Access username and password from the values object
        console.log("seller in catalog", seller.data);
        const { whatsappAccountName, catalogName } = values;
        try {
            const response = await axios.post(`https://wc.adaptnxt.in/catalog`, {
                name: catalogName,
                whatsapp_name: whatsappAccountName,
                business_id: parseInt(businessId, 10),
                sellerId: seller.data.id
            }, {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                // Navigate to the dashboard page
                console.log("Whatsapp account", response.data)
                setIsModalVisible(false);
                const catalogData: CatalogInterface[] = response.data.map((item:any)=> ({
                    id: item.id,
                    name: item.name,
                    meta_catalog_id: item.meta_catalog_id,
                    sellerId: item.seller ? item.seller.id : null, // Assign sellerId if seller exists, otherwise null
                    whatsappAccount: item.whatsappAccount ? item.whatsappAccounts.whatsapp_name : null, // Assign whatsapp_account_name if whatsappAccount exists, otherwise null
                  }));
                setCatalog((prevCatalog: any) => [
                    ...prevCatalog,
                    catalogData[0],
                ]);
                setCatalogName(catalogName);
                 // Assuming the data you want to set is in response.data
            } else {
                // Handle other status codes or errors here
                console.error('Failed to create WhatsApp account');
            }
        } catch (error) {
            // Handle network errors or other exceptions
            console.error('Error creating WhatsApp account:', error);
        }

    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const modalcolumns: TableColumnsType<CatalogInterface> = [
        {
            title: 'ID', dataIndex: 'id', key: 'id',
        },
        { title: 'Name', dataIndex: 'name', key: 'name', sorter: (a, b) => a.name.length - b.name.length, },
        { title: 'Meta Id', dataIndex: 'meta_catalog_id', key: 'meta_catalog_id',sorter: (a, b) => a.meta_catalog_id.length - b.meta_catalog_id.length,},
        { title: 'Whatsapp Chatbot', dataIndex: 'whatsappAccount', key: 'whatsappAccount', sorter: (a, b) => a.whatsappAccount.length - b.whatsappAccount.length },
        // {
        //     title: 'Access Token',
        //     dataIndex: 'meta_access_token',
        //     key: 'meta_access_token',
        //     sorter: (a, b) => a.meta_access_token.length - b.meta_access_token.length
        // },
        {
            title: 'Action',
            dataIndex: 'operation',
            key: 'operation',
            render: () => (
                <Space size="small" className='table-action'>
                    <a>Pause</a>
                    <a>Stop</a>
                </Space>
            ),
        },

    ];

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '7rem' }}>
            {(!catalog) ? (
                <>
                    <PlusCircleOutlined style={{ fontSize: '50px', color: '#7209B7' }} onClick={showModal} />
                    <p style={{ fontSize: "18px", fontFamily: 'DM Sans' }}>Create Catalog</p>
                </>
            ) : (
                <>
                    <div style={{ width: "100%" }}>
                        <div style={{ display: "flex", justifyContent: "flex-start", marginBottom: "1rem" }}>
                            <Button type='primary' onClick={showModal} >Create Catalog</Button>
                        </div>
                        <Table
                            className='products-table'
                            rowKey="id"
                            columns={modalcolumns}
                            dataSource={catalog}
                            scroll={{ x: 'max-content' }}
                            size='small'
                        />
                    </div>
                </>
            )}
            <div>
                {/* Content for Whatsapp Accounts */}
                {
                    seller ? <Modal
                        title="Create Catalog"
                        visible={isModalVisible}
                        onCancel={handleCancel}
                        footer={null}
                    >
                        <Form name="create-account-form" onFinish={onFinish}>
                            <Form.Item
                                label="Catalog Name"
                                name="catalogName"
                                rules={[{ required: true, message: 'Please input Catalog Name!' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Whatsapp Account Name"
                                name="whatsappAccountName"
                                rules={[{ required: true, message: 'Please input Whatsapp Name!' }]}
                            >
                                <Input value={whatsappName} />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Create
                                </Button>
                            </Form.Item>
                        </Form>
                    </Modal> : ""
                }
            </div>
        </div>
    );
};

export default Catalog;