import React, { useState } from 'react';
import { Form, Input, Button, Col, Row } from 'antd';
import { Card } from 'antd';
import axios from 'axios';
import pointnxtSVG from '../SVG.svg';
import whatsappSVG from '../whatsapp.svg';
import '../App.css';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setSeller, setSellerData, setToken } from '../actions'; // Import your actions
import { RootState } from '../reducers';

interface LoginProps {
    sellerData: any;
    updateSellerData: (data: any) => void;
    token: any;
    setSeller: (data: any) => void;
}

const Login: React.FC<any> = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const onFinish = async (values: any) => {
        // Access username and password from the values object
        const { username, password } = values;

        let data = JSON.stringify({
            "email": username,
            "password": password
        });
        const timestamp = new Date().getTime();
        const uniqueId = username.replace(/[^a-zA-Z0-9]/g, ''); // Remove non-alphanumeric characters from the username
        const pnxtSellerId = `PNXT-WA-SELLR-${timestamp}`;

        const sellerObject = await axios.post('https://wc.adaptnxt.in/', {
            email: username,
            password: password,
            pnxt_seller_id: pnxtSellerId
        }, {
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json',
            },
        });
        localStorage.setItem('seller',JSON.stringify(sellerObject));
        dispatch(setSeller(sellerObject));

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://api.pnxt.in/api/v1/core/auth/token',
            headers: {
                'sec-ch-ua': '"Chromium";v="116", "Not)A;Brand";v="24", "Google Chrome";v="116"',
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Referer': 'https://demo.pointnxt.com/',
                'sec-ch-ua-mobile': '?0',
                'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/116.0.0.0 Safari/537.36',
                'sec-ch-ua-platform': '"Windows"'
            },
            data: data
        };

        await axios.request(config)
            .then((response) => {
                if (response.status === 200) {
                    // Navigate to the dashboard page
                    console.log("Response Data", response.data.result)
                    dispatch(setToken(response.data.result.token));
                    localStorage.setItem("token",response.data.result.token);
                    localStorage.setItem('token', response.data.result.token)
                    let config = {
                        method: 'get',
                        maxBodyLength: Infinity,
                        url: 'https://api.pnxt.in/api/v1/auth/core/auth/user',
                        headers: {
                            'authority': 'api.pnxt.in',
                            'accept': 'application/json, text/plain, */*',
                            'accept-language': 'en-US,en;q=0.9',
                            'authorization': `Bearer ${response.data.result.token}`,
                            'origin': 'https://demo.pointnxt.com',
                            'referer': 'https://demo.pointnxt.com/',
                            'sec-ch-ua': '"Chromium";v="116", "Not)A;Brand";v="24", "Google Chrome";v="116"',
                            'sec-ch-ua-mobile': '?0',
                            'sec-ch-ua-platform': '"Windows"',
                            'sec-fetch-dest': 'empty',
                            'sec-fetch-mode': 'cors',
                            'sec-fetch-site': 'cross-site',
                            'user-agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/116.0.0.0 Safari/537.36'
                        }
                    };

                    axios.request(config)
                        .then((response) => {
                            console.log("User Data", response.data.result);
                            localStorage.setItem('sellerData', JSON.stringify(response.data.result))
                            if (response) {
                                dispatch(setSellerData(response.data.result));
                                localStorage.setItem("sellerData",JSON.stringify(response.data.result));
                                // updateSellerData(response.data.result)

                                navigate('/dashboard');
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };


    return (
        <div className="login-container" style={{
            height: '100vh', overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }}>
            <div className="login-card" style={{ height: '100%' }}>
                <Row justify="center" align="middle" style={{ height: '100%' }}>
                    <Col span={24}>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginLeft: "2rem" }}>
                            <img style={{ width: "35px", height: "35px" }} alt="example" src="https://ps.w.org/pointnxt/assets/icon-256x256.png?rev=2887491" />
                            <p style={{ fontSize: "25px", fontFamily: "DM Sans", fontWeight: 700 }}>PointNXT</p>
                        </div>
                        <Card className="hover" style={{ width: "472px", marginLeft: "3.5rem" }} cover={<img style={{ width: "40px", height: "40px", marginLeft: "47%", backgroundColor: "#F6F8FB", borderRadius: "40px", marginTop: "10px" }} alt="example" src={pointnxtSVG} />}>
                            <p style={{ width: "372px", height: "36px", fontSize: "24px", fontFamily: "DM Sans", fontWeight: 700, marginLeft: "2rem", color: "#181E25" }}>Sign in with your PointNXT email</p>
                            <p style={{ width: "385px", height: "20px", fontFamily: "DM Sans", fontWeight: 400, fontSize: "14px", color: "#788BA5", marginLeft: "1.6rem" }}>Use your PointNXT email to sign into your management hub</p>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <p style={{ width: "74px", height: "20px", fontFamily: "DM Sans", fontWeight: 400, fontSize: "16px" }}>Selling on</p>
                                <img style={{ width: "30px", height: "30px" }} alt="example" src={whatsappSVG} />
                                <p style={{ width: "186px", height: "20px", fontFamily: "DM Sans", fontWeight: 400, fontSize: "16px" }}>WhatsApp made simple!</p>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", width: "390px", height: "21px", marginLeft: "1.5rem" }}>
                                <div style={{ flex: 1, borderBottom: "1px solid #788BA5" }}></div>
                                <p style={{ width: "171px", height: "21px", color: "#788BA5", fontFamily: "DM Sans", fontWeight: 400, fontSize: "12px" }}>START SELLING ON WHATSAPP</p>
                                <div style={{ flex: 1, borderBottom: "1px solid #788BA5" }}></div>
                            </div>
                            <Form
                                name="login-form"
                                onFinish={onFinish}
                                layout="vertical"
                                className="login-form"
                            >
                                <label style={{ width: "35px", height: "20px", fontFamily: "DM Sans", fontWeight: 400, fontSize: "14px", color: "#181E25", display: "flex", justifyContent: "flex-start", marginBottom: "1rem", marginLeft: "1.2rem" }}>Email</label>
                                <Form.Item
                                    name="username"
                                    rules={[{ required: true, message: 'Please input your email!' }]}
                                >
                                    <Input style={{ width: "390px", height: "40px", border: "1px solid #5E378D", borderRadius: "6px" }} />
                                </Form.Item>
                                <label style={{ width: "35px", height: "20px", fontFamily: "DM Sans", fontWeight: 400, fontSize: "14px", color: "#181E25", display: "flex", justifyContent: "flex-start", marginBottom: "1rem", marginLeft: "1.2rem" }}>Password</label>
                                <Form.Item
                                    name="password"
                                    rules={[{ required: true, message: 'Please input your password!' }]}
                                >
                                    <Input.Password style={{ width: "390px", height: "40px", border: "1px solid #788BA5", borderRadius: "6px" }} />
                                </Form.Item>

                                <Form.Item>
                                    <Button style={{ display: "flex", width: "390px", height: "40px", marginLeft: "1rem", backgroundColor: "#5E378D", justifyContent: "center", alignItems: "center", color: "white" }} htmlType="submit" className="login-form-button">
                                        Sign in
                                    </Button>
                                </Form.Item>
                            </Form>
                            <div style={{ display: "flex", width: "306px", height: "21px", marginLeft: "4rem" }}>
                                <p style={{ fontFamily: "DM Sans", fontWeight: 400, fontSize: "14px", color: "#181E25" }}>Don't have an account yet in PointNXT?</p>
                                <Link to="https://demo.pointnxt.com/register" style={{ textDecoration: 'none' }}>
                                <p style={{ fontFamily: "DM Sans", fontWeight: 400, fontSize: "14px", color: "#5E378D",marginLeft:"0.2rem" }}>Sign up</p>
                                </Link>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default Login;
