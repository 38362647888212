import React from 'react';
import { DownOutlined, TagsFilled } from '@ant-design/icons';
import type { DatePickerProps, MenuProps, TableColumnsType } from 'antd';
import { Badge, Button, Dropdown, Space, Table, Input, Modal, Menu, Descriptions, Avatar, Card, Row, Col, Form, DatePicker } from 'antd';
import { SyncOutlined, SearchOutlined } from '@ant-design/icons';
import EyeIcon from '../ph_eye.svg';
import DeleteIcon from '../DeleteOutlined.svg';
import axios from 'axios';
import { CatalogInterface } from '../App';
import EditIcon from '../iconamoon_edit-thin.svg';
import { Product } from './Products';
import Search from 'antd/es/input/Search';
import { useNavigate } from 'react-router-dom';
import { ColumnType } from 'antd/es/table';
import Highlighter from 'react-highlight-words';
import { FilterConfirmProps } from 'antd/es/table/interface';
import { useSelector } from 'react-redux';
//import './products.css';

enum ProductStatus {
    active = 'active',
    inactive = 'inactive',
    // Add more status values if needed
}

export interface Customer {
    id: number;
    name: string;
    catalog: CatalogInterface,
    wa_id: string;
}


interface Address {
    id: number;
    name: string;
    phone_number: string;
    floor_number: string | null;
    building_name: string;
    tower_number: string;
    house_number: string;
    address: string;
    landmark_area: string;
    city: string;
    pin_code: string;
    state: string;
    awb_no: string;
    courier_name: string;
    ship_date: string;
}

export interface OrderProducts {
    id: number;
    sellerId: number;
    sku: string;
    title: string;
    description: string;
    sales_price: number;
    currency: string;
    url: string;
    item_condition: string;
    brand: string;
    availability: string;
    category: string;
    image_url: string;
    stock: number;
    qty: number;
    price: number;
    created_at: Date;
    updated_at: Date;
}

export interface Order {
    id: number;
    order_no: number;// Make avatar property optional
    order_price: number;
    order_status: string;
    order_date: Date;
    ship_date: Date;
    awb_no: string;
    payment_details: PaymentDetails;
    shippingAddress: Address | null;
    billingAddress: Address | null;
    orderProducts: OrderProducts[];
    invoice: number;
    catalog: CatalogInterface;
    customer: Customer;
    courier_name: string;

}




interface PaymentDetails {
    method: string;
}

interface ExpandedDataType {
    id: number;
    product: Product;
    qty: number;
    price: number;
}
type DataIndex = keyof Order;

const items = [
    { key: '1', label: 'Action 1' },
    { key: '2', label: 'Action 2' },
];

const Orders: React.FC<any> = (props) => {
    const [expandedRowKeys, setExpandedRowKeys] = React.useState<React.Key[]>([]);
    const [catalogFilters, setCatalogFilters] = React.useState([]);
    const [data, setData] = React.useState<Order[]>([]);
    const [initialData, setInitialData] = React.useState<Order[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [searchTerm, setSearchTerm] = React.useState('');
    const [filterData, setFilterData] = React.useState<Order[]>([]);
    const [selectedMenuItem, setSelectedMenuItem] = React.useState('2');
    const [selectedRows, setSelectedRows] = React.useState<Order[]>([]);
    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const [orderProducts, setOrderProdcuts] = React.useState<ExpandedDataType[]>([]);
    const [modalVisible, setModalVisible] = React.useState(false);
    const [selectedRowForDeletion, setSelectedRowForDeletion] = React.useState<Order | null>(null);
    const [showProduct, setShowProduct] = React.useState(false);
    const [product, setProduct] = React.useState<any>();
    const [channels, setChannels] = React.useState<any[]>([]);
    const [editOrder, setEditOrder] = React.useState<any>();
    const [editModal, setEditModal] = React.useState(false);
    const [productDeleteModal, setProductDeleteModal] = React.useState(false);
    const [orderStartDate, setOrderStartDate] = React.useState('');
    const [orderEndDate, setOrderEndDate] = React.useState('');
    const [searchText, setSearchText] = React.useState('');
    const [searchedColumn, setSearchedColumn] = React.useState('');
   const seller = useSelector((state:any)=>state.seller.seller);
   const token = useSelector((state:any)=>state.auth.token);
    const navigate = useNavigate();
    let searchInput: any;

    const handleShowProduct = (record: any) => {
        console.log("Product Record", record)
        setShowProduct(true);
        setProduct(record);
    }
    const expandedRowRender = (record: any) => {
        console.log("Expand Record", record)
        const columns: TableColumnsType<OrderProducts> = [
            {
                title: 'ID', dataIndex: 'id', key: 'id',
            },
            {
                title: "Image",
                dataIndex: "image_url",  // this is the value that is parsed from the DB / server side
                render: theImageURL => <img alt={theImageURL} src={theImageURL} style={{ borderRadius: '50%', width: '50px', height: '50px' }} />
            },
            { title: 'SKU', dataIndex: 'sku', key: 'sku', sorter: (a, b) => a.sku.length - b.sku.length, },
            { title: 'Product', dataIndex: 'title', key: 'title' },
            { title: 'Quantity', dataIndex: 'qty', key: 'qty' },
            {
                title: 'Price',
                dataIndex: 'price',
                key: 'price',

            },
            {
                title: 'Action',
                dataIndex: 'operation',
                key: 'operation',
                render: (text, record) => (

                    <Space size="middle">
                        <div>

                            <a onClick={() => handleShowProduct(record)}><img src={EyeIcon} alt="Edit" className="edit-icon" /></a>
                            <Modal
                                visible={showProduct}
                                onCancel={() => setShowProduct(false)}
                                footer={null}
                                width="65%"
                                maskStyle={{ background: "rgba(255, 255, 255, 0.1)" }}
                                bodyStyle={{ maxHeight: 'max-content', overflow: 'auto' }}
                            >
                                <Row style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Card
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            flexDirection: 'column',
                                            margin: '10px',
                                            borderRadius: '12px',
                                            minWidth: '60%',
                                            minHeight: '500px',
                                            height: '100%',
                                            boxShadow: '10px 10px 4px 0px rgba(128, 128, 128, 0.10)',
                                        }}
                                    >
                                        <Row gutter={24} style={{ justifyContent: 'center', display: 'flex' }}>
                                            <Col style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                                                <img
                                                    src={
                                                        product?.image_url === null
                                                            ? 'https://static.vecteezy.com/system/resources/previews/005/337/799/original/icon-image-not-found-free-vector.jpg'
                                                            : product?.image_url
                                                    }
                                                    width={220}
                                                    height={320}
                                                    style={{
                                                        borderRadius: '12px',
                                                        marginLeft: '15px',
                                                        boxShadow: '5px 5px 4px 0px rgba(128, 128, 128, 0.10)',
                                                    }}
                                                    alt="Product Image"
                                                />
                                            </Col>
                                            <Col style={{ paddingLeft: '30px' }}>
                                                <p style={{ fontSize: '30px', fontWeight: '400' }}>{product?.title}</p>
                                                <div style={{ width: '750px' }}>
                                                    <p
                                                        style={{
                                                            fontSize: '15px',
                                                            display: '-webkit-box',
                                                            WebkitLineClamp: 4,
                                                            WebkitBoxOrient: 'vertical',
                                                            overflow: 'hidden',
                                                        }}
                                                    >
                                                        {product?.description}
                                                    </p>
                                                </div>
                                                <Descriptions bordered>
                                                    <Descriptions.Item label="Price">{product?.price}</Descriptions.Item>
                                                    {/* <Descriptions.Item label="Stock In Hand">{product?.stock}</Descriptions.Item> */}
                                                    <Descriptions.Item label="Product SKU">{product?.sku}</Descriptions.Item>
                                                </Descriptions>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Row>
                            </Modal>
                        </div>
                        <div>
                            <a onClick={() => {
                                setProduct(record)
                                setProductDeleteModal(true)
                            }}><img src={DeleteIcon} alt="Delete" style={{ cursor: "pointer", marginRight: "10px" }} className="edit-icon" /></a>
                            <Modal
                                visible={productDeleteModal}
                                onCancel={() => setProductDeleteModal(false)}
                                footer={null}
                                width="670px"
                                bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
                            >
                                <div>
                                    <p style={{ fontFamily: "DM Sans", fontWeight: 400, fontSize: "20px", color: "black" }}>Delete Product</p>
                                    <hr />
                                    <p style={{ fontSize: "18px", fontFamily: "DM Sans", fontWeight: 400, display: "flex", justifyContent: "center" }}>Are you sure you want to delete {product?.title || 'this order'} order?<br />This action cannot be undone.</p>
                                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                        <Button key="back" onClick={() => {
                                            setProduct(null)
                                            setProductDeleteModal(false)
                                        }}>Cancel</Button>
                                        <Button key="submit" style={{ marginLeft: "1rem", backgroundColor: "#FF4D4F", color: "white" }} onClick={() => handleProductDelete(product)}>Delete</Button>
                                    </div>
                                </div>
                            </Modal>
                        </div>
                    </Space>
                ),
            },
        ];
        return <Table columns={columns} dataSource={record.orderProducts} pagination={false} />;
    };

    const handleProductDelete = async (item: any) => {
        const product = await axios.delete(`https://wc.adaptnxt.in/orders/orderProductDelete/${item.id}`);
        console.log("Item", product)
        setProduct(null);
        setProductDeleteModal(false)
        window.location.reload();
    }

    const openDeleteModal = (record: Order) => {
        setSelectedRowForDeletion(record);
        setModalVisible(true);
    };

    const getAvatar = (channel_id: any) => {

        let ch = channels.find((c: any) => c.value === channel_id)!;

        return ch ? ch.avatar : '#';

    };
    // Function to handle the delete action
    const handleDelete = async (selectedRowForDeletion: any) => {
        console.log("Selected Row", selectedRowForDeletion)
        if (selectedRowForDeletion) {
            const row = await axios.delete(`https://wc.adaptnxt.in/orders/${selectedRowForDeletion.id}`)
            setModalVisible(false);
            setSelectedRowForDeletion(null);
            window.location.reload()
        }
    };
    const filterByOrderDate = () => {
        setInitialData(data);
        const filteredOrders = data.filter((order) => {
            const orderDate = new Date(order.order_date);
            const order_start_date = new Date(orderStartDate);
            const order_end_date = new Date(orderEndDate);
            return orderDate >= order_start_date && orderDate <= order_end_date;
        });
        console.log("Filtered orders", filteredOrders);
        setData(filteredOrders);
    }
    const filterByShipDate = () => {
        setInitialData(data);
        const filteredOrders = data.filter((order) => {
            const shipDate = new Date(order.ship_date);
            const order_start_date = new Date(orderStartDate);
            const order_end_date = new Date(orderEndDate);
            return shipDate >= order_start_date && shipDate <= order_end_date;
        });
        console.log("Filtered orders", filteredOrders);
        setData(filteredOrders);
    }

    const handleEditModal = (record: any) => {
        console.log("Edit Order", record)
        setEditOrder(record)
        setEditModal(true);
    }
    const updateStartDate: DatePickerProps['onChange'] = (date: any, dateString: string) => {
        setOrderStartDate(dateString);
    };
    const updateEndDate: DatePickerProps['onChange'] = (date: any, dateString: string) => {
        setOrderEndDate(dateString);
    };

    const getColumnDateSearchProps = (dataIndex: string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
            <div style={{ padding: 8 }}>
                <Form labelCol={{ span: 9 }}>
                    <Form.Item
                        name="order_start_date"
                        //label={formatMessage({ id: 'app.order.report.startdate' })}
                        rules={[{ required: true, message: 'Please enter Start Date' }]}
                    >
                        <DatePicker
                            mode="date"
                            format="YYYY-MM-DD"
                            onChange={updateStartDate}
                        // setOrderStartDate('');
                        // setOrderEndDate('');
                        />
                    </Form.Item>

                    <Form.Item
                        name="order_end_date"
                        // value={e.target.value ? [e.target.value] : []}
                        //label={formatMessage({ id: 'app.order.report.enddate' })}
                        rules={[{ required: true, message: 'Please enter End Date' }]}
                    >
                        <DatePicker mode="date" format="YYYY-MM-DD" onChange={updateEndDate} />
                    </Form.Item>

                    <Space style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                        {
                            dataIndex === 'order_date' ? <Button
                                type="primary"
                                onClick={() => filterByOrderDate()}
                                icon={<SearchOutlined />}
                                size="small"
                                style={{ width: 90 }}
                            >
                                Search
                            </Button> :
                                <Button
                                    type="primary"
                                    onClick={() => filterByShipDate()}
                                    icon={<SearchOutlined />}
                                    size="small"
                                    style={{ width: 90 }}
                                >
                                    Search
                                </Button>
                        }

                        {/* <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
                            Reset
                        </Button> */}
                        <Button
                            type="link"
                            size="small"
                            onClick={() => {
                                //setData(initialData);
                                confirm({ closeDropdown: true });
                                // setSearchText(selectedKeys[0]);
                                // setSearchIndex(dataIndex);
                            }}
                        >
                            Close
                        </Button>
                    </Space>
                </Form>
            </div>
        ),
        filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value: any, record: any) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        onFilterDropdownOpenChange: (visible: any) => {
            if (visible) {
                // setTimeout(() => searchInput.select(), 100);
            }
        },
        render: (text: { toString: () => any }) => text,
    });
    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };


    const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<Order> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={`${selectedKeys[0] || ''}`}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText((selectedKeys as string[])[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            !!record[dataIndex]
                ?.toString()
                .toLowerCase()
                .includes((value as string).toLowerCase()),
        onFilterDropdownOpenChange: visible => {
            if (visible) {
                //setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });


    const columns: TableColumnsType<Order> = [
        { title: 'Order No.', dataIndex: 'order_no', key: 'order_no', sorter: (a, b) => a.order_no - b.order_no, },
        { title: 'Price', dataIndex: 'order_price', key: 'order_price', sorter: (a, b) => a.order_price - b.order_price, },
        {
            title: 'Status',
            dataIndex: 'order_status',
            key: 'order_status',
            sorter: (a, b) => a.order_status.length - b.order_status.length,
            filters: [
                // Define the filters based on your channels
                { text: 'processing', value: 'processing' },
                { text: 'On hold', value: 'On hold' },
                { text: 'Pending payment', value: 'Pending payment' },
                { text: 'Failed', value: 'Failed' },
                { text: 'Awb created', value: 'Awb created' },
                { text: 'completed', value: 'completed' },
                { text: 'packed', value: 'packed' },
                { text: 'shipped', value: 'shipped' },
                { text: 'delivered', value: 'delivered' },
                { text: 'await-pickup', value: 'await-pickup' },
                { text: 'picked-up', value: 'picked-up' },
                { text: 'refunded', value: 'refunded' },
                // Add more filters as needed
            ],
            onFilter: (value, record) => record.order_status.includes(value as string)
        },
        {
            title: 'Customer Name', dataIndex: 'customer', key: 'customer', render: (text, record) => record.customer ? record.customer.name : '', sorter: (a, b) => {
                const customerA = `${a.customer?.name}`;
                const customerB = `${b.customer?.name}`;
                return customerA.localeCompare(customerB)
            }
        },
        {
            title: 'Order Date', dataIndex: 'order_date', key: 'order_date',
            ...getColumnDateSearchProps('order_date'),
            render: (text: string, record: Order) => {
                // Your rendering logic here
                return <span>{text}</span>;
            },
        },
        {
            title: 'AWB No', dataIndex: 'awb_no', key: 'awb_no',
            ...getColumnSearchProps('awb_no')
        },
        {
            title: 'Ship Date', dataIndex: 'ship_date', key: 'ship_date',
            ...getColumnDateSearchProps('ship_date'),
            render: (text: string, record: Order) => {
                // Your rendering logic here
                return <span>{text}</span>;
            },
        },
        {
            title: 'Courier Name', dataIndex: 'courier_name', key: 'courier_name',
            filters: [
                // Define the filters based on your channels
                { text: 'Delhivery', value: 'Delhivery' },
                { text: 'DTDC', value: 'DTDC' },
                { text: 'Dunzo', value: 'Dunzo' },
                { text: 'Amazon', value: 'Amazon' },
                { text: 'Aramex', value: 'Aramex' },
                { text: 'Blue Dart', value: 'Blue Dart' },
                { text: 'Fedex', value: 'Fedex' },
                { text: 'Nimbus Post', value: 'Nimbus Post' },
                { text: 'Pickrr', value: 'Pickrr' },
                { text: 'Porter', value: 'Porter' },
                { text: 'Shiprocket', value: 'Shiprocket' },
                { text: 'Shypmax', value: 'Shypmax' },
                { text: 'Stamps', value: 'Stamps' },
                // Add more filters as needed
            ],
            onFilter: (value, record) => record.courier_name.includes(value as string)
        },
        {
            title: 'Address', dataIndex: 'shippingAddress', key: 'shippingAddress', render: (text, record) => {
                if (!record.shippingAddress) {
                    return 'Address not available';
                }
                const addressParts = [
                    record.shippingAddress?.name,
                    record.shippingAddress?.phone_number,
                    record.shippingAddress?.address,
                    record.shippingAddress?.building_name,
                    record.shippingAddress?.tower_number,
                    record.shippingAddress?.house_number,
                    record.shippingAddress?.floor_number,
                    record.shippingAddress?.landmark_area,
                    record.shippingAddress?.city,
                    record.shippingAddress?.state,
                    record.shippingAddress?.pin_code,
                ];

                // Filter out null or empty values
                const filteredAddressParts = addressParts.filter(part => part !== null && part !== '');

                // Join the filtered parts into the address string
                const addressString = filteredAddressParts.join(',');

                return addressString;
            },
            sorter: (a, b) => {
                // Define a custom sorting logic based on the address or other properties.
                // You may need to modify this logic to match your specific requirements.
                const addressA = `${a.shippingAddress?.name},${a.shippingAddress?.phone_number},${a.shippingAddress?.address},${a.shippingAddress?.building_name},${a.shippingAddress?.city},${a.shippingAddress?.floor_number},${a.shippingAddress?.landmark_area},${a.shippingAddress?.pin_code}`;
                const addressB = `${b.shippingAddress?.name},${b.shippingAddress?.phone_number},${b.shippingAddress?.address},${b.shippingAddress?.building_name},${b.shippingAddress?.city},${b.shippingAddress?.floor_number},${b.shippingAddress?.landmark_area},${b.shippingAddress?.pin_code}`;
                return addressA.localeCompare(addressB);
            },
        },
        {
            title: 'Catalog', dataIndex: 'catalog', key: 'catalog',
            filters: catalogFilters,
            onFilter: (value, record) => record.catalog.name.includes(value as string),
            render: (text, record) => record.catalog ? record.catalog.name : '',
        },
        {
            title: 'Action',
            dataIndex: 'operation',
            key: 'operation',
            render: (text, record) => (
                <Space size="middle">
                    {/* <a><img src={EyeIcon} alt="Edit" className="edit-icon" /></a> */}
                    <a onClick={() => openDeleteModal(record)}><img src={DeleteIcon} alt="Delete" style={{ cursor: "pointer", marginRight: "10px" }} className="edit-icon" /></a>
                    <div>
                        <a onClick={() => handleEditModal(record)}><img src={EditIcon} alt="Edit" className="edit-icon" /></a>
                        {editOrder && (<Modal
                            visible={editModal}
                            onCancel={() => {
                                setEditOrder(null); // Clear the currently edited product
                                setEditModal(false);
                            }}
                            footer={null}
                            maskStyle={{ background: "rgba(255, 255, 255, 0.1)" }}
                            width="670px"
                            bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
                        >
                            <p style={{ fontFamily: "DM Sans", fontWeight: 400, fontSize: "20px", color: "black" }}>Edit Order</p>
                            <hr />
                            <Form name="create-account-form" onFinish={onFinish} initialValues={editOrder}  >
                                <Form.Item
                                    label="Id"
                                    name="id"
                                >
                                    <Input style={{ width: "394px", height: "30px", marginLeft: "4.4rem" }} />
                                </Form.Item>
                                <Form.Item
                                    label="Order No"
                                    name="order_no"
                                >
                                    <Input style={{ width: "394px", height: "30px", marginLeft: "1.5rem" }} />
                                </Form.Item>
                                <Form.Item label="Shipping Address" style={{ display: "inline-block", width: "100%" }}> 
                                    <Form.Item
                                        label="Name"
                                        name={["shippingAddress", "name"]}
                                    >
                                        <Input style={{ width: "86.5%",marginLeft:"3.5rem" }} />
                                    </Form.Item>
                                    <Form.Item
                                        label="Phone Number"
                                        name={["shippingAddress", "phone_number"]}
                                    >
                                        <Input style={{ width: "100%" }} />
                                    </Form.Item>
                                    <Form.Item
                                        label="Floor Number"
                                        name={["shippingAddress", "floor_number"]}
                                    >
                                        <Input style={{ width: "98%",marginLeft:"0.5rem" }} />
                                    </Form.Item>
                                    <Form.Item
                                        label="Building Name"
                                        name={["shippingAddress", "building_name"]}
                                    >
                                        <Input style={{ width: "100%" }} />
                                    </Form.Item>
                                    <Form.Item
                                        label="House Number"
                                        name={["shippingAddress", "house_number"]}
                                    >
                                        <Input style={{ width: "100%" }} />
                                    </Form.Item>
                                    <Form.Item
                                        label="Tower Number"
                                        name={["shippingAddress", "tower_number"]}
                                    >
                                        <Input style={{ width: "100%" }} />
                                    </Form.Item>
                                    <Form.Item
                                        label="Address"
                                        name={["shippingAddress", "address"]}
                                    >
                                        <Input style={{ width: "89.5%",marginLeft:"2.7rem" }} />
                                    </Form.Item>
                                    <Form.Item
                                        label="Landmark Area"
                                        name={["shippingAddress", "landmark_area"]}
                                    >
                                        <Input style={{ width: "100%" }} />
                                    </Form.Item>
                                    <Form.Item
                                        label="City"
                                        name={["shippingAddress", "city"]}
                                    >
                                        <Input style={{ width: "84.6%",marginLeft:"4.2rem" }} />
                                    </Form.Item>
                                    <Form.Item
                                        label="State"
                                        name={["shippingAddress", "state"]}
                                    >
                                        <Input style={{ width: "85.8%",marginLeft:"3.8rem" }} />
                                    </Form.Item>
                                    <Form.Item
                                        label="Pin Code"
                                        name={["shippingAddress", "pin_code"]}
                                    >
                                        <Input style={{ width: "91.2%",marginLeft:"2.2rem" }} />
                                    </Form.Item>
                                    {/* Add more Form.Item components for other shippingAddress properties */}
                                </Form.Item>
                                <Form.Item>
                                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                        <Button onClick={() => {
                                            setEditOrder(null)
                                            setEditModal(false)
                                        }}>
                                            Cancel
                                        </Button>
                                        <Button style={{ backgroundColor: '#473355', color: 'white', marginLeft: "1rem" }} htmlType="submit">
                                            Update
                                        </Button>
                                    </div>
                                </Form.Item>
                            </Form>
                        </Modal>)
                        }
                    </div>
                </Space>
            )
        },
    ];
    React.useEffect(() => {
        setLoading(true);
        let data = JSON.stringify({
            "filters": {
                "channel_id": null,
                "sku": null,
                "title": null,
                "search": ""
            }
        });

        fetchOrders();
        console.log("Seller token",token);
        getChannels();
    }, []);
    async function getChannels() {

        try {
            let data = JSON.stringify({
                "filters": {
                    "channel_id": null,
                    "sku": null,
                    "title": null,
                    "search": ""
                }
            });

            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: 'https://api.pnxt.in/api/v1/auth/mcm/channel/all',
                headers: {
                    'sec-ch-ua': '"Chromium";v="116", "Not)A;Brand";v="24", "Google Chrome";v="116"',
                    'sec-ch-ua-mobile': '?0',
                    'Authorization': `Bearer ${token}`,
                    'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/116.0.0.0 Safari/537.36',
                    'Content-Type': 'application/json',
                    'Accept': 'application/json, text/plain, */*',
                    'Referer': 'https://demo.pointnxt.com/',
                    'sec-ch-ua-platform': '"Windows"'
                },
                data: data
            };

            const channels = await axios.request(config);
            const channelNames = channels.data.result.map((item: any) =>
                ({ label: item.name, value: item.id, avatar: item.avatar }));
            setChannels(channelNames);
            setLoading(false)
        } catch (error) {
            console.log("seller data", error);
        }


    }

    const fetchOrders = async () => {
        const sellerJSON = localStorage.getItem('seller');
        try {
            if (sellerJSON) {
                const seller = JSON.parse(sellerJSON);
                const orders = await axios.get(`https://wc.adaptnxt.in/orders/getOrders/${seller.id}`);
                const updatedOrders = orders.data.reverse();
                const uniqueValuesSet = new Set();
                const catalog_filters = updatedOrders.reduce((uniqueFilters: any, item: any) => {
                    const value = item.catalog.name;

                    if (!uniqueValuesSet.has(value)) {
                        uniqueValuesSet.add(value);
                        uniqueFilters.push({
                            text: value,
                            value: value,
                        });
                    }

                    return uniqueFilters;
                }, []);

                console.log(catalog_filters);
                setCatalogFilters(catalog_filters);
                setData(updatedOrders);
                setOrderProdcuts(updatedOrders.orderProducts);
                setLoading(false)
            }
        } catch (error) {
            console.log(error);
            navigate('/login');
        }
    }
    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const start = () => {
        setLoading(true);
        // ajax request after empty completing
        setTimeout(() => {

        }, 1000);
    };

    const onFinish = async (values: any) => {
        console.log("Update address values", values)
        const updateOrderDto = {
            shippingAddress: values.shippingAddress,
            billingAddress: values.shippingAddress
            // Other properties you want to update
        };
        const updatedOrder = await axios.put(`https://wc.adaptnxt.in/orders/${values.id}`, updateOrderDto)
        console.log("Updated Order", updatedOrder);
        setEditOrder(null);
        setEditModal(false);
        window.location.reload();
    }

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
        const updatedSelectedRows = data.filter((item) => newSelectedRowKeys.includes(item.id));
        setSelectedRows(updatedSelectedRows);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    // const handleChange = (e: any) => {
    //     setSearchTerm(e.target.value);
    //     if (searchTerm !== '') {
    //         const filteredData = data.filter((item) => item.title.includes(e.target.value))

    //         // Remove duplicates if needed
    //         setFilterData(filteredData);
    //     }
    // }

    const handleMenuItemClick = (menuItemKey: any) => {
        // Handle the click event, perform any necessary actions

        // Show the modal
        setIsModalVisible(true);
    };

    const handleRefresh = () => {
        setLoading(true)
        fetchOrders();
    }

    const handleChange = (e: any) => {
        setSearchTerm(e.target.value);
    }

    const onSearch = async () => {
        if (searchTerm !== '') {
            setInitialData(data);
            console.log("Initial Data", initialData);
            const filteredData = data.filter((item) => {
                const orderNoString = item.order_no.toString();
                const searchTermString = searchTerm.toString();

                // Use includes or another condition based on your filtering criteria
                return orderNoString.includes(searchTermString);
            })
            // Remove duplicates if needed
            setData(filteredData);
        } else if (searchTerm === '') {
            console.log(initialData);
            setData(initialData);
        }
    }


    return (
        <>
            <div style={{ marginBottom: 16, display: "flex", justifyContent: "flex-end" }}>
                <div style={{ width: "264px", height: "32px", borderRadius: "5px", border: "1px solid #473355" }}>
                    <Search placeholder="Search Order No here..." onChange={handleChange} onSearch={onSearch} value={searchTerm} style={{ width: "100%" }} />
                </div>
                <Button style={{ backgroundColor: "#473355", color: "white", marginLeft: "1rem" }} loading={loading} onClick={handleRefresh}>
                    <SyncOutlined /> Refresh
                </Button>
            </div>

            {/* Modal for selected rows */}
            <Modal
                title="Create Meta Collection"
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
                width="100%"
                bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
            >
                <Table
                    className='products-table'
                    rowKey="id"
                    columns={columns}
                    dataSource={selectedRows}
                    scroll={{ x: 'max-content' }}
                />
                <p style={{ fontSize: "16px", fontFamily: "DM Sans", display: "flex", justifyContent: "center" }}>Are you sure want to add these products to meta catalog?</p>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button key="back" onClick={handleCancel} onClickCapture={() => setIsModalVisible(false)}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" style={{ marginLeft: "1rem" }} loading={loading} onClick={() => setIsModalVisible(false)}>
                        Submit
                    </Button>
                </div>
            </Modal>
            <Table
                className='products-table'
                rowKey="id"
                columns={columns}
                expandable={{ expandedRowRender, defaultExpandedRowKeys: ['0'] }}
                dataSource={data}
                loading={loading}
            />
            <Modal
                visible={modalVisible}
                onCancel={() => setModalVisible(false)}
                footer={null}
                width="670px"
                bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
            >
                {selectedRowForDeletion && (
                    <div>
                        <p style={{ fontFamily: "DM Sans", fontWeight: 400, fontSize: "20px", color: "black" }}>Delete Order {selectedRowForDeletion.order_no}</p>
                        <hr />
                        <p style={{ fontSize: "18px", fontFamily: "DM Sans", fontWeight: 400, display: "flex", justifyContent: "flex-start" }}>Are you sure you want to delete {selectedRowForDeletion.order_no} order? This action cannot be undone.</p>
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button key="back" onClick={() => setModalVisible(false)}>Cancel</Button>
                            <Button key="submit" style={{ marginLeft: "1rem", backgroundColor: "#FF4D4F", color: "white" }} onClick={() => handleDelete(selectedRowForDeletion)}>Delete</Button>
                        </div>
                    </div>
                )}
            </Modal>
        </>
    );
};

export default Orders;


