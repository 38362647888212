import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    UploadOutlined,
    UserOutlined,
    VideoCameraOutlined,
    ShoppingCartOutlined
} from '@ant-design/icons';
import { Layout, Menu, Button, theme, Dropdown, MenuProps, Space, Avatar, Form, Modal, Input, Divider } from 'antd';
import { BrowserRouter as Router, Route, Link, useNavigate } from 'react-router-dom';
import axios from 'axios'; // Import Router, Route, and Switch
import Profile from './Profile';
import Password from 'antd/es/input/Password';
import Products from './Products';
import WhatsappAccount from './WhatsappAccount';
import Orders from './Orders';
import Catalog from './Catalog';
import Categories from './categories';
import PointNXTLogo from '../Pointnxt Logo_White_transparent 1.svg';
import { ReactComponent as ProductIcon } from '../Group.svg';
import { ReactComponent as ChatbotIcon } from '../majesticons_chat-line.svg';
import { ReactComponent as CategoryIcon } from '../carbon_expand-categories.svg';
import { ReactComponent as ShoppingCartIcon } from '../fluent_cart-24-regular.svg';
import { setSeller, setWhatsapp } from '../actions';
import '../App.css';
import Typography from 'antd/es/typography/Typography';
import LoadingComponent from '../loadingComponent';

const { Header, Sider, Content } = Layout;
interface LoginProps {
    sellerData: any;
    updateSellerData: (data: any) => void;
    token: string;
    whatsappAccount: any;
    setWhatsappAccount: (data: any) => void;
    catalog: any;
    setCatalog: (data: any) => void;
    seller: any;
    setSeller: (data: any) => void;
    whatsappCatalog: any;
    setWhatsappCatalog: (data: any) => void;
    categories: any;
    setCategories: (data: any) => void;
}

const Dashboard: React.FC<any> = () => {
    const [collapsed, setCollapsed] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalVisibility, setIsModalVisibility] = useState(false);
    const storedSelectedItem = localStorage.getItem('selectedItem');
    const defaultSelectedItem = '2'; // Provide a default value
    const initialSelectedItem = storedSelectedItem ?? defaultSelectedItem;
    const [selectedMenuItem, setSelectedMenuItem] = useState(initialSelectedItem);
    const [pnxtSellerId,setpnxtSellerId] = useState('');
    const [sellerInfo,setSellerInfo] =useState<any>();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = useSelector((state:any) => state.auth.token);
    const seller = useSelector((state:any)=>state.seller.seller);
    const sellerData = useSelector((state: any) => state.sellerData.sellerData);
    const whatsappAccount = useSelector((state:any)=>state.whatsapp.whatsapp);
    const catalog = useSelector((state:any)=>state.catalog.catalog);
    //setSellerInfo(sellerData);
    async function updateSellerdb(sellerData: any) {
        // const token = localStorage.getItem('token')
        const seller = await axios.post('https://wc.adaptnxt.in/', {
            email: sellerData.email ,
            firstName: sellerData?.first_name,
            lastName: sellerData?.last_name ,
            phone: sellerData?.phone ,
            token: token,
            business_id: sellerData?.business_id ,
        }, {
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json',
            },
        })
        console.log("DashBoard Seller", seller)
        // setSeller(seller.data);
        setpnxtSellerId(seller.data.pnxt_seller_id)
        localStorage.setItem('sellerData', JSON.stringify(sellerData));
        localStorage.setItem('seller', JSON.stringify(seller.data));
        // dispatch(setSeller(seller.data))
    }
    useEffect(() => {
        // const token = localStorage.getItem('token');
        const sellerJSON = localStorage.getItem('seller');
        if (sellerJSON) {
            const sellerData = JSON.parse(sellerJSON)

        const selectedItem = localStorage.getItem('selectedItem');
            console.log("Seller",seller);
            setSellerInfo(sellerData); 
            setSelectedMenuItem(selectedItem ? selectedItem : '2')
            console.log("Token", sellerData);

            updateSellerdb(sellerData);
        }
    }, [])
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const items: MenuProps['items'] = [
        // {
        //     key: '1',
        //     label: (
        //         <span onClick={() => setSelectedMenuItem('1')}>
        //             Profile
        //         </span>
        //     ),
        // },
        {
            key: '7',
            label: (
                <span onClick={() => setSelectedMenuItem('7')}>
                    Profile
                </span>
            ),
        },
        {
            key: '6',
            label: (
                <span onClick={() => navigate('/login')}>
                    Logout
                </span>
            ),
        },
       

        // {
        //     key: '2',
        //     label: (
        //         <span onClick={() => setSelectedMenuItem('2')}>
        //             Whatsapp Accounts
        //         </span>
        //     ),
        // },
    ];
    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const handleModalCancel = () => {
        setIsModalVisibility(false);
    };
    const onFinish = async (values: any) => {
        // Access username and password from the values object
        const { phoneNumberId, wabaId, metaAccessToken, whatsappAccountName, BusinessId } = values;
        try {
            const response = await axios.post(`https://wc.adaptnxt.in/whatsapp-accounts/${seller.data.id}`, {
                whatsapp_name: whatsappAccountName,
                phone_number_id: parseInt(phoneNumberId, 10),
                waba_id: parseInt(wabaId, 10),
                meta_access_token: metaAccessToken.toString(),
                sellerId: seller.data.id
            }, {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                // Navigate to the dashboard page
                console.log("Whatsapp account", response.data)
                setIsModalVisible(false);
                dispatch(setWhatsapp((prevWhatsappAccount: any) => [
                    ...prevWhatsappAccount,
                    response.data,
                ]))
                // setWhatsappAccount((prevWhatsappAccount: any) => [
                //     ...prevWhatsappAccount,
                //     response.data,
                // ]); // Assuming the data you want to set is in response.data
                await axios.post('https://wc.adaptnxt.in/', {
                    business_id: BusinessId
                }, {
                    headers: {
                        'Accept': '*/*',
                        'Content-Type': 'application/json',
                    },
                })
            } else {
                // Handle other status codes or errors here
                console.error('Failed to create WhatsApp account');
            }
        } catch (error) {
            // Handle network errors or other exceptions
            console.error('Error creating WhatsApp account:', error);
        }

    };


    const renderContent = () => {
        console.log('Selected Menu Item', selectedMenuItem)
        localStorage.setItem('selectedItem', selectedMenuItem);
        switch (selectedMenuItem) {
            case '7':
                return (
                    <div>
                        {/* Content for Profile */}
                        <Profile />
                    </div>
                );
            case '2':
                return (
                    <div>
                        {
                            <Products seller={seller} token={token}  /> 
                        }
                        {/* Content for Whatsapp Accounts */}

                    </div>
                );
            case '3':
                return (
                    <Categories  />
                );
            case '4':
                return (
                    <WhatsappAccount whatsappAccount={whatsappAccount}/>
                );
            case '8':
                return (
                    <Catalog loading/>
                )
            case '5':
                return (
                    <Orders />
                );
            default:
                return null;
        }
    };

    const item = [
        { key: '1', label: 'Action 1' },
        { key: '2', label: 'Action 2' },
    ];

    return (
        <Layout hasSider style={{ height: "100vh", overflow: "auto" }}>
            <Sider width={260} trigger={null} style={{ backgroundColor: "#473355",height: '100vh' }} collapsible collapsed={collapsed} >
                <img style={{ height: "40px", marginTop: "16px", width: collapsed ? "80px" : "210px" }} alt="example" src={PointNXTLogo} />
                <hr style={{ width: collapsed ? "80px" : "210px" }} />
                <div className="demo-logo-vertical" />
                <Menu
                    theme="dark"
                    mode="inline"
                    style={{ backgroundColor: "#473355", width: collapsed ? "65px" : "220px", marginLeft: collapsed ? "0rem" : "1.2rem" }}
                    defaultSelectedKeys={['1']}
                    selectedKeys={[selectedMenuItem]}
                >
                    {[
                        {
                            key: '2',
                            icon: <ProductIcon />,
                            label: 'Products',
                        },
                        {
                            key: '4',
                            icon: <ChatbotIcon />,
                            label: 'Chatbots',
                        },
                        {
                            key: '3',
                            icon: <CategoryIcon />,
                            label: 'Categories',
                        },
                        {
                            key: '5',
                            icon: <ShoppingCartIcon />,
                            label: 'Orders',
                        },
                    ].map((item) => (
                        <Menu.Item
                            key={item.key}
                            onClick={() => setSelectedMenuItem(item.key)}
                            style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "0.5rem" }}
                            className={`menu-item ${item.key === selectedMenuItem ? 'selected' : ''}`}
                        >
                            <span className="menu-item-icon">{item.icon}</span>
                            {!collapsed && <span className="menu-item-label">{item.label}</span>}
                        </Menu.Item>
                    ))}
                </Menu>
            </Sider>
            <Layout>
                <Header style={{ display: "flex", justifyContent: "space-between", padding: 0, background: colorBgContainer, }}>
                    <Button
                        type="text"
                        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                        onClick={() => setCollapsed(!collapsed)}
                        style={{
                            fontSize: '16px',
                            width: 45,
                            height: 45,
                            left: 0,
                        }}
                    />
                    <div style={{display:"flex",justifyContent:"space-evenly",alignItems:"center"}}>
                        <Typography style={{fontWeight:"bold"}}>{pnxtSellerId}</Typography>
                    <Dropdown menu={{ items }} placement="topRight" trigger={['click']} arrow>
                        <Space style={{ marginRight: "2rem",marginLeft:"0.5rem"}}>
                            <Avatar size={50} icon={<UserOutlined />} />
                        </Space>
                    </Dropdown>
                    </div>
                </Header>
                <Content
                    style={{
                        margin: '24px 0px',
                        padding: 24,
                        minHeight: 280,
                        background: colorBgContainer,
                        overflow: 'auto',
                        flex: 1
                    }}
                >
                    {renderContent()}
                </Content>
            </Layout>
        </Layout>
    );
};

export default Dashboard;