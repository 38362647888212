import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Button } from 'antd';
import axios from 'axios';

const cardStyle: React.CSSProperties = {
  width: '100%',
  margin: '0 auto',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  borderRadius: '8px',
  padding: '16px',
};

const rowStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  marginBottom: '16px',
  alignItems: "center"
};

const inputStyle: React.CSSProperties = {
  borderRadius: '5px',
  height: '25px',
  width: "20rem",
  marginLeft: "2rem"
};

const textStyle: React.CSSProperties = {
  fontSize: '16px',
};

interface AddressDetails {
  id: number;
  name: string;
  phone_number: string;
  floor_number: string;
  building_name: string;
  address: string;
  tower_number: string;
  city: string;
  state: string;
  pin_code: string;
  landmark_area: string;
}

const Profile: React.FC<any> = ({ sellerData }) => {
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addressDetails, setAddressDetails] = useState<AddressDetails>({
    id: 0,
    name: '',
    phone_number: '',
    floor_number: '',
    building_name: '',
    address: '',
    tower_number: '',
    city: '',
    state: '',
    pin_code: '',
    landmark_area: '',
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setAddressDetails({ ...addressDetails, [name]: value });
  };
  const fetchAddressData = async () => {
    const sellerJSON = localStorage.getItem('seller');
    if (sellerJSON) {
      const seller = JSON.parse(sellerJSON);
      const address = await axios.get(`https://wc.adaptnxt.in/${seller.id}/getUserAddress`)
      if (address.data) {
        console.log("inside address")
        setAddressDetails(address.data)
        setIsUpdateMode(true)
      } else {
        console.log("Outside address")
        setIsUpdateMode(false)
      }

    }
  }

  useEffect(() => {
    fetchAddressData()
  }, [])

  const handleAddAddress = async () => {
    try {
      // Replace 'YOUR_API_ENDPOINT' with the actual API endpoint
      setLoading(true)
      const sellerJSON = localStorage.getItem('seller');
      if (sellerJSON) {
        const seller = JSON.parse(sellerJSON);
        const response = await axios.post(`https://wc.adaptnxt.in/${seller.id}/addUserAddress`, addressDetails);
        console.log('API Response:', response);
        // Handle the API response as needed
        setLoading(false)
        setIsUpdateMode(true)
      }
    } catch (error) {
      console.error('API Error:', error);
      // Handle the error as needed
    }
  };
  const handleUpdateAddress = async () => {
    try {
      // Replace 'YOUR_API_ENDPOINT' with the actual API endpoint
      setLoading(true)
      const sellerJSON = localStorage.getItem('seller');
      if (sellerJSON) {
        const seller = JSON.parse(sellerJSON);
        const response = await axios.patch(`https://wc.adaptnxt.in/${seller.id}/updateUserAddress`, addressDetails);
        console.log('API Response:', response);
        // Handle the API response as needed
        setIsUpdateMode(true)
        setLoading(false)
      }
    } catch (error) {
      console.error('API Error:', error);
      // Handle the error as needed
    }
  };

  return (
    <Card title="Seller Profile" style={cardStyle}>
      <div style={{ display: "flex",justifyContent:"space-around" }}>
        <div>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "30rem" }}>
              <label style={textStyle}>
                <strong style={{ marginLeft: "1rem" }}>Name:</strong>
              </label>
              <input
                type="text"
                name="name"
                value={addressDetails.name}
                onChange={handleInputChange}
                style={{
                  borderRadius: '5px',
                  height: '25px',
                  width: "20rem",
                }}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "30rem", marginTop: "1rem" }}>
              <label style={textStyle}>
                <strong style={{ marginLeft: "1rem" }}>Phone Number:</strong>
              </label>
              <input
                type="text"
                name="phone_number"
                value={addressDetails.phone_number}
                onChange={handleInputChange}
                style={{
                  borderRadius: '5px',
                  height: '25px',
                  width: "20rem",
                }}
              />
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "30rem", marginTop: "1rem" }}>
              <label style={textStyle}>
                <strong style={{ marginLeft: "1rem" }}>Floor Number:</strong>
              </label>
              <input
                type="text"
                name="floor_number"
                value={addressDetails.floor_number}
                onChange={handleInputChange}
                style={{
                  borderRadius: '5px',
                  height: '25px',
                  width: "20rem",
                }}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "30rem", marginTop: "1rem" }}>
              <label style={textStyle}>
                <strong style={{ marginLeft: "1rem" }}>Building Name:</strong>
              </label>
              <input
                type="text"
                name="building_name"
                value={addressDetails.building_name}
                onChange={handleInputChange}
                style={{
                  borderRadius: '5px',
                  height: '25px',
                  width: "20rem",
                }}
              />
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "30rem", marginTop: "1rem" }}>
              <label style={textStyle}>
                <strong style={{ marginLeft: "1rem" }}>Address:</strong>
              </label>
              <input
                type="text"
                name="address"
                value={addressDetails.address}
                onChange={handleInputChange}
                style={{
                  borderRadius: '5px',
                  height: '25px',
                  width: "20rem",
                }}
              />
            </div>
          </div>
        </div>
        <div>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "30rem" }}>
              <label style={textStyle}>
                <strong style={{ marginLeft: "1rem" }}>Landmark:</strong>
              </label>
              <input
                type="text"
                name="landmark_area"
                value={addressDetails.landmark_area}
                onChange={handleInputChange}
                style={{
                  borderRadius: '5px',
                  height: '25px',
                  width: "20rem",
                }}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "30rem", marginTop: "1rem" }}>
              <label style={textStyle}>
                <strong style={{ marginLeft: "1rem" }}>Tower Number:</strong>
              </label>
              <input
                type="text"
                name="tower_number"
                value={addressDetails.tower_number}
                onChange={handleInputChange}
                style={{
                  borderRadius: '5px',
                  height: '25px',
                  width: "20rem",
                }}
              />
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "30rem", marginTop: "1rem" }}>
              <label style={textStyle}>
                <strong style={{ marginLeft: "1rem" }}>City:</strong>
              </label>
              <input
                type="text"
                name="city"
                value={addressDetails.city}
                onChange={handleInputChange}
                style={{
                  borderRadius: '5px',
                  height: '25px',
                  width: "20rem",
                }}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "30rem", marginTop: "1rem" }}>
              <label style={textStyle}>
                <strong style={{ marginLeft: "1rem" }}>State:</strong>
              </label>
              <input
                type="text"
                name="state"
                value={addressDetails.state}
                onChange={handleInputChange}
                style={{
                  borderRadius: '5px',
                  height: '25px',
                  width: "20rem",
                }}
              />
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "30rem", marginTop: "1rem" }}>
              <label style={textStyle}>
                <strong style={{ marginLeft: "1rem" }}>Pin Code:</strong>
              </label>
              <input
                type="text"
                name="pin_code"
                value={addressDetails.pin_code}
                onChange={handleInputChange}
                style={{
                  borderRadius: '5px',
                  height: '25px',
                  width: "20rem",
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center",marginTop:"2rem" }}>
        {
          isUpdateMode ?
            <Button type="primary" onClick={handleUpdateAddress} loading={loading}>
              Update Profile
            </Button> :
            <Button type="primary" onClick={handleAddAddress} loading={loading}>
              Save Profile
            </Button>
        }
      </div>
    </Card>
  );
};

export default Profile;


// async function getSellerData(sellerData: any) {
//   const sellerId = localStorage.getItem('sellerId');
//   console.log("Seller Data", sellerData)
//   const response = await axios.patch(`https://wc.adaptnxt.in/${sellerId}`, {
//     firstName: sellerData.first_name,
//     lastName: sellerData.last_name,
//     phone: sellerData.phone,
//   });
//   // Handle the response if needed
//   return response.data;
// }



