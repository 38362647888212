import React from 'react';
import { DownOutlined, SyncOutlined, PlusOutlined, TagsFilled } from '@ant-design/icons';
import type { MenuProps, SelectProps, TableColumnsType } from 'antd';
import { Badge, Button, Dropdown, Space, Table, Input, Modal, Menu, Col, Row, Card, Tag, Form, Select, Tooltip, Avatar, Descriptions, Pagination } from 'antd';
import axios from 'axios';
import "./index.css";
import EyeIcon from '../ph_eye.svg';
import Search from 'antd/es/input/Search';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setWhatsapp, setWhatsappCatalog } from '../actions';

//import './products.css';

enum ProductStatus {
    active = 'active',
    inactive = 'inactive',
    // Add more status values if needed
}


export interface Product {
    id: number;
    sku: string;
    avatar?: string; // Make avatar property optional
    title: string;
    currency: string;
    price: number;
    status: ProductStatus;
    stock:number;
    qty: number;
    weight: number;
    dimension_length: number;
    dimension_breadth: number;
    dimension_height: number;
    description: string;
    hscode: string;
    channelids: number[];
}


interface ExpandedDataType {
    id: number;
    whatsappAccount: string;
}

const items = [
    { key: '1', label: 'Action 1' },
    { key: '2', label: 'Action 2' },
];

const Products: React.FC<any> = (props) => {
    const [expandedRowKeys, setExpandedRowKeys] = React.useState<React.Key[]>([]);
    const { seller,token} = props;
    const [selectedValues, setSelectedValues] = React.useState<string[]>([]);
    const [channels, setChannels] = React.useState<any[]>([]);
    const [options, setOptions] = React.useState<any[]>([]);
    const [showProduct, setShowProduct] = React.useState(false);
    const [product, setProduct] = React.useState<Product>();
    const dispatch = useDispatch();
    // const seller = useSelector((state:any)=>state.seller.seller);
    const whatsappCatalog = useSelector((state:any)=> state.whatsappCatalog.whatsappCatalog);

    const navigate =useNavigate();
    const expandedRowRender = () => {
        const columns: TableColumnsType<ExpandedDataType> = [
            { title: 'Id', dataIndex: 'id', key: 'id' },
            { title: 'Whatsapp account', dataIndex: 'whatsappAccount', key: 'whatsappAccount' },
        ];

        const dummyData = [
            { id: 1, whatsappAccount: 'WhatsApp Account 1' },
            { id: 2, whatsappAccount: 'WhatsApp Account 2' },
            { id: 3, whatsappAccount: 'WhatsApp Account 3' },
            // Add more data as needed
        ];
        return <Table columns={columns} dataSource={dummyData} pagination={false} />;
    };
    const modalcolumns: TableColumnsType<Product> = [
        {
            title: 'ID', dataIndex: 'id', key: 'id',
        },
        { title: 'SKU', dataIndex: 'sku', key: 'sku', sorter: (a, b) => a.sku.length - b.sku.length, },
        { title: 'Title', dataIndex: 'title', key: 'title', sorter: (a, b) => a.title.length - b.title.length, },
        { title: 'Price', dataIndex: 'price', key: 'price', sorter: (a, b) => a.price - b.price, },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status: ProductStatus) => (
                <Tag color={status === 'active' ? 'success' : 'error'}>
                    {status}
                </Tag>
            ),
        },
        { title: 'Stock in hand', dataIndex: 'qty', key: 'qty', sorter: (a, b) => a.qty - b.qty, },
        // { title: 'Weight', dataIndex: 'weight', key: 'weight', sorter: (a, b) => a.weight - b.weight, },
        // { title: 'Length', dataIndex: 'dimension_length', key: 'dimension_length', sorter: (a, b) => a.dimension_length - b.dimension_length, },
        // { title: 'Breadth', dataIndex: 'dimension_breadth', key: 'dimension_breadth', sorter: (a, b) => a.dimension_breadth - b.dimension_breadth },
        // { title: 'Height', dataIndex: 'dimension_height', key: 'dimension_height', sorter: (a, b) => a.dimension_height - b.dimension_height },
        // { title: 'HS Code', dataIndex: 'hscode', key: 'hscode', sorter: (a, b) => a.hscode.length - b.hscode.length },
        { title: 'Channel', dataIndex: 'channelids', key: 'channelids' },
    ];

    const columns: TableColumnsType<Product> = [
        {
            title: "Image",
            dataIndex: "avatar",  // this is the value that is parsed from the DB / server side
            render: theImageURL => {
                return theImageURL ?
                    <img alt={theImageURL} src={theImageURL} style={{ borderRadius: '50%', width: '50px', height: '50px' }} /> :
                    <Avatar size={50} icon={<TagsFilled />} />
            }
        },
        { title: 'Product Name', dataIndex: 'title', key: 'title', sorter: (a, b) => a.title.length - b.title.length, },
        { title: 'SKU', dataIndex: 'sku', key: 'sku', sorter: (a, b) => a.sku.length - b.sku.length, },
        { title: 'Price', dataIndex: 'price', key: 'price', sorter: (a, b) => a.price - b.price, },
        { title: 'Stock in hand', dataIndex: 'qty', key: 'qty', sorter: (a, b) => a.qty - b.qty, },
        // { title: 'Weight', dataIndex: 'weight', key: 'weight', sorter: (a, b) => a.weight - b.weight, },
        // { title: 'Length', dataIndex: 'dimension_length', key: 'dimension_length', sorter: (a, b) => a.dimension_length - b.dimension_length, },
        // { title: 'Breadth', dataIndex: 'dimension_breadth', key: 'dimension_breadth', sorter: (a, b) => a.dimension_breadth - b.dimension_breadth },
        // { title: 'Height', dataIndex: 'dimension_height', key: 'dimension_height', sorter: (a, b) => a.dimension_height - b.dimension_height },
        // { title: 'HS Code', dataIndex: 'hscode', key: 'hscode', sorter: (a, b) => a.hscode.length - b.hscode.length },
        {
            title: 'Channel', dataIndex: 'channelids', key: 'channelids',
            filters: [
                // Define the filters based on your channels
                { text: 'WooCommerce', value: 1 },
                { text: 'Shopify', value: 2 },
                { text: 'Amazon', value: 3 },
                // Add more filters as needed
              ],
            onFilter: (value, record) => record.channelids.includes(value as number),
            render: (_, { channelids }) => (

                <Tooltip title={channelids.map(channelid => getChannelName(channelid)).join(', ')}>

                    <Avatar style={{ backgroundColor: 'white', height: '40px', width: '40px' }}>

                        {channelids.map(channelid => (

                            <Avatar key={channelid} src={getAvatar(channelid)} />

                        ))}

                    </Avatar>

                </Tooltip>

            )
        },
        {
            title: 'Action',
            dataIndex: 'operation',
            key: 'operation',
            render: (text, record) => (
                <Space size="middle" className='table-action'>
                    <div>
                        <a onClick={() => handleShowProduct(record)}><img src={EyeIcon} alt="Edit" className="edit-icon" /></a>
                        <Modal
                            visible={showProduct}
                            onCancel={() => setShowProduct(false)}
                            footer={null}
                            width="65%"
                            maskStyle={{ background: "rgba(255, 255, 255, 0.1)" }}
                            bodyStyle={{ maxHeight: 'max-content', overflow: 'auto' }}
                        >
                            <Row style={{ display: 'flex', justifyContent: 'center' }}>
                                <Card
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                        margin: '10px',
                                        borderRadius: '12px',
                                        minWidth: '60%',
                                        minHeight: '500px',
                                        height: '100%',
                                        boxShadow: '10px 10px 4px 0px rgba(128, 128, 128, 0.10)',
                                    }}
                                >
                                    <Row gutter={24} style={{ justifyContent: 'center', display: 'flex' }}>
                                        <Col style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                                            <img
                                                src={
                                                    product?.avatar === null
                                                        ? 'https://static.vecteezy.com/system/resources/previews/005/337/799/original/icon-image-not-found-free-vector.jpg'
                                                        : product?.avatar
                                                }
                                                width={220}
                                                height={320}
                                                style={{
                                                    borderRadius: '12px',
                                                    marginLeft: '15px',
                                                    boxShadow: '5px 5px 4px 0px rgba(128, 128, 128, 0.10)',
                                                }}
                                                alt="Product Image"
                                            />
                                        </Col>
                                        <Col style={{ paddingLeft: '30px' }}>
                                            <p style={{ fontSize: '30px', fontWeight: '400' }}>{product?.title}</p>
                                            <div style={{ width: '750px' }}>
                                                <p
                                                    style={{
                                                        fontSize: '15px',
                                                        display: '-webkit-box',
                                                        WebkitLineClamp: 4,
                                                        WebkitBoxOrient: 'vertical',
                                                        overflow: 'hidden',
                                                    }}
                                                >
                                                    {product?.description}
                                                </p>
                                            </div>
                                            <Descriptions bordered>
                                                <Descriptions.Item label="Price">{product?.price}</Descriptions.Item>
                                                <Descriptions.Item label="Stock In Hand">{product?.qty}</Descriptions.Item>
                                                <Descriptions.Item label="Product SKU">{product?.sku}</Descriptions.Item>
                                                <Descriptions.Item label="Channels">
                                                    <Avatar.Group size="small" style={{ paddingRight: '10px' }}>
                                                        {product?.channelids.map(channelid => (
                                                            <Avatar key={channelid} src={getAvatar(channelid)} />
                                                        ))}
                                                    </Avatar.Group>
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Dimensions (lxbxh)">
                                                    {product?.dimension_length} x {product?.dimension_breadth} x {product?.dimension_height}
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Weight (gms)">{product?.weight}</Descriptions.Item>
                                            </Descriptions>
                                        </Col>
                                    </Row>
                                </Card>
                            </Row>
                        </Modal>
                    </div>
                    <a onClick={() => handleRowClick(record)}><PlusOutlined /></a>
                </Space>
            ),
        },
    ];

    const handleRowClick = async (record: any) => {
        try {
            const response = await axios.get(`https://wc.adaptnxt.in/whatsapp-accounts/seller/${seller.id}`);
            console.log("seller data", response);
            dispatch(setWhatsapp(response.data.whatsappAccounts));
            // setWhatsappAccount(response.data.whatsappAccounts)
            setSelectedRows([record]);
            const newOptions = response.data.whatsappAccounts.map((item: any) => ({
                label: item.whatsapp_name,
                value: item.id,
            }));
            setOptions(newOptions);
            // Show the modal
            setIsModalVisible(true);
        } catch (error) {
            console.log(error)
        }
    }


    const handleShowProduct = (record: any) => {
        setShowProduct(true);
        setProduct(record);
        console.log("Product Record", record)
    }

    const getChannelName = (channelid: any) => {
        let ch = channels.find(c => c.value === channelid)!;
        return ch ? ch.label : '-NA-';
    }

    const getAvatar = (channel_id: any) => {

        let ch = channels.find(c => c.value === channel_id)!;

        return ch ? ch.avatar : '#';

    };

    const [data, setData] = React.useState<Product[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [searchTerm, setSearchTerm] = React.useState('');
    const [filterData, setFilterData] = React.useState<Product[]>([]);
    const [selectedMenuItem, setSelectedMenuItem] = React.useState('2');
    const [selectedRows, setSelectedRows] = React.useState<Product[]>([]);
    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const [pagination, setPagination] = React.useState({
        current: 1,    // current page
        pageSize: 10,  // number of items per page
        total: 100,    // total number of data items
    });
    React.useEffect(() => {
        const sellerJSON = localStorage.getItem('seller');
        if (sellerJSON) {
            const sellerData = JSON.parse(sellerJSON)
            console.log("Products", sellerData, seller)
        }
       
            getChannels(); // Call getChannels only if seller is available
        fetchData(pagination.current, pagination.pageSize, searchTerm);
       
    }, []);


    async function getChannels() {
        try {
            console.log("Seller Info",seller)
            let data = JSON.stringify({
                "filters": {
                    "channel_id": null,
                    "sku": null,
                    "title": null,
                    "search": ""
                }
            });

            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: 'https://api.pnxt.in/api/v1/auth/mcm/channel/all',
                headers: {
                    'sec-ch-ua': '"Chromium";v="116", "Not)A;Brand";v="24", "Google Chrome";v="116"',
                    'sec-ch-ua-mobile': '?0',
                    'Authorization': `Bearer ${token}`,
                    'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/116.0.0.0 Safari/537.36',
                    'Content-Type': 'application/json',
                    'Accept': 'application/json, text/plain, */*',
                    'Referer': 'https://demo.pointnxt.com/',
                    'sec-ch-ua-platform': '"Windows"'
                },
                data: data
            };

            const channels = await axios.request(config);
            if(channels.status === 401){
                navigate('/login');
            } else if(channels.status === 200){
                const channelNames = channels.data.result.map((item: any) =>
                ({ label: item.name, value: item.id, avatar: item.avatar }));
                setChannels(channelNames);
            }
        } catch (error) {
            console.log("seller data", error);
        }


    }
    const fetchData = (page: any, pageSize: any, searchTerm: any) => {
        const token = localStorage.getItem('token')
        setLoading(true);
        let data = JSON.stringify({
            "filters": {
                "channel_id": null,
                "sku": null,
                "title": null,
                "search": searchTerm.toLowerCase()
            }
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `https://api.pnxt.in/api/v1/auth/mcm/product/filter?page=${page}&per_page=${pageSize}&column=&order=asc`,
            headers: {
                'sec-ch-ua': '"Chromium";v="116", "Not)A;Brand";v="24", "Google Chrome";v="116"',
                'sec-ch-ua-mobile': '?0',
                'Authorization': `Bearer ${token}`,
                'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/116.0.0.0 Safari/537.36',
                'Content-Type': 'application/json',
                'Accept': 'application/json, text/plain, */*',
                'Referer': 'https://demo.pointnxt.com/',
                'sec-ch-ua-platform': '"Windows"'
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                console.log("Response data", response.data.result);
                setData(response.data.result.data);
                setPagination({ ...pagination, total: response.data.result.meta.total, current: page });
                setLoading(false)
            })
            .catch((error) => {
                navigate('/login');
                console.log(error);
            });
    };



    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const renderContent = () => {
        switch (selectedMenuItem) {
            case '6':
                return (
                    <div>

                    </div>
                );
            case '7':
                return (
                    <div>
                        {/* Content for Whatsapp Accounts */}
                        <Products />
                    </div>
                );
            default:
                return null;
        }
    };
    const items = [
        {
            key: '6',
            label: 'WhatsApp Account 1'
        },
        {
            key: '7',
            label: 'WhatsApp Account 2'
        },
    ];

    const start = () => {
        // ajax request after empty completing
        setTimeout(() => {

        }, 1000);
    };

    const handlePageChange = (page: any, pageSize: any) => {
        console.log("Page", page)
        // Update the pagination state when the page changes
        setPagination({ ...pagination, current: page });
        fetchData(page, pageSize, searchTerm);
    };

    const handlePageSizeChange = (current: any, size: any) => {
        setPagination({ ...pagination, pageSize: size });
        fetchData(current, size, searchTerm)
    };

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
        const updatedSelectedRows = data.filter((item) => newSelectedRowKeys.includes(item.id));
        setSelectedRows(updatedSelectedRows);
    };

    const handleSelectChange = async (values: any) => {
        console.log("Values", values)
        const whatsapp_catalog = await axios.get(`https://wc.adaptnxt.in/catalog/${values[0]}/get_catalog`);
        dispatch(setWhatsappCatalog(whatsapp_catalog.data))
        // setWhatsappCatalog(whatsapp_catalog.data)
        setSelectedValues(values);
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    const handleChange = (e: any) => {
        setSearchTerm(e.target.value);
        // if (searchTerm !== '') {
        //     const filteredData = data.filter((item) => item.title.toLowerCase().includes(e.target.value.toLowerCase()))

        //     // Remove duplicates if needed
        //     setFilterData(filteredData);
        // }
    }

    const onSearch = () => {
        fetchData(1, pagination.pageSize, searchTerm)
    }

    const handleAddProducts = () => {
        console.log("Slected Rows", selectedRows)
        console.log("whatsapp catalog", whatsappCatalog)
        selectedRows.map(async (item: any) => {
            const data = {
                sellerId: seller.id,
                sku: item.sku,
                title: item.title,
                description: "New Product",
                price: parseInt(item.price, 10),
                sales_price: parseInt(item.price, 10),
                currency: "INR",
                url: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.freeiconspng.com%2Fimages%2Fno-image-icon&psig=AOvVaw2HaSCZQWKqeNLTuwrtVZB0&ust=1695464408734000&source=images&cd=vfe&opi=89978449&ved=0CBAQjRxqFwoTCIi4jLeAvoEDFQAAAAAdAAAAABAI",
                condition: "new",
                brand: "products",
                availability: "in stock",
                category: "products",
                image_url: item.avatar ? item.avatar : 'https://industrialphysics.com/wp-content/uploads/2022/08/product-image-coming-soon-139.png',
                stock: 20,
                catalogId: whatsappCatalog.id,
                channelIds: item.channelids
            };
            await axios.post('https://wc.adaptnxt.in/products', data, {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                }
            })
                .then((response) => {
                    console.log('Response:', response.data);
                    setIsModalVisible(false);
                })
                .catch((error) => {
                    console.error('Error:', error);
                });

        })
    }
    const handleMenuItemClick = async (menuItemKey: any) => {
        console.log("Menu Item", menuItemKey)
        // Handle the click event, perform any necessary actions
        try {
            const response = await axios.get(`https://wc.adaptnxt.in/whatsapp-accounts/seller/${seller.id}`);
            console.log("seller data", response);
            dispatch(setWhatsapp(response.data.whatsappAccounts));
            // setWhatsappAccount(response.data.whatsappAccounts)
            const newOptions = response.data.whatsappAccounts.map((item: any) => ({
                label: item.whatsapp_name,
                value: item.id,
            }));
            setOptions(newOptions);
            console.log("Menu item key", menuItemKey)
            const whatsapp_catalog = await axios.get(`https://wc.adaptnxt.in/catalog/${seller.id}/get_catalog`)
            console.log("Whatsapp Account catalog", whatsapp_catalog);
            setWhatsappCatalog(whatsapp_catalog.data)
            // Show the modal
            setIsModalVisible(true);
        } catch (error) {
            console.log(error)
        }

    };
    const handleRefresh = () => {
        console.log("Refresh Pagination", pagination)
        fetchData(pagination.current, pagination.pageSize, searchTerm);
    }

    const handleMetaOption = async () => {
        try {
            const response = await axios.get(`https://wc.adaptnxt.in/whatsapp-accounts/seller/${seller.data.id}`);
            console.log("seller data", response);
            dispatch(setWhatsapp(response.data.whatsappAccounts));
            // setWhatsappAccount(response.data.whatsappAccounts)
        } catch (error) {
            console.log("seller data", error);
        }
    }


    return (
        <Col>
            <Row>
                <Card style={{ width: "100%" }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div>
                            <Button size='middle' style={{ marginRight: "1rem", backgroundColor: "#473355", color: "white" }} onClick={() => fetchData(pagination.current, pagination.pageSize, searchTerm)} loading={loading} >
                                <SyncOutlined />Sync Products
                            </Button>
                            {/* <Dropdown
                                overlay={
                                    <Menu>
                                        {whatsappAccount.map((item: any) => (
                                            <Menu.Item key={item?.id} onClick={() => handleMenuItemClick(item?.id)}>
                                                {item?.whatsapp_name}
                                            </Menu.Item>
                                        ))}
                                    </Menu>
                                }
                                placement="topRight"
                                trigger={['click']}
                                disabled={!hasSelected}
                                arrow
                            > */}
                            <Button style={{ backgroundColor: "#473355", color: "white" }} onClick={handleMenuItemClick} disabled={!hasSelected}>
                                <PlusOutlined /> Add to chatbot
                            </Button>
                            {/* </Dropdown> */}
                        </div>
                        <div style={{ width: "40%" }}>
                            <Search placeholder="Search Product or SKU here..." onChange={handleChange} onSearch={onSearch} value={searchTerm} style={{ width: "70%" }} />
                            {/* <Input placeholder="Search product title here" onChange={handleChange} value={searchTerm} /> */}
                        </div>
                        <Button style={{ backgroundColor: "#473355", color: "white" }} onClick={handleRefresh} loading={loading} >
                            <SyncOutlined /> Refresh
                        </Button>
                    </div>
                </Card>
            </Row>
            <Row>
                <div style={{ width: "100%" }}>
                    <Table

                        rowKey="id"
                        columns={columns}
                        rowSelection={rowSelection}
                        pagination={false}
                        // expandable={{ expandedRowRender, defaultExpandedRowKeys: ['0'] }}
                        dataSource={data}
                        size='large'
                        loading={loading}
                    />
                    <Pagination total={pagination.total}
                        pageSize={pagination.pageSize}
                        current={pagination.current}
                        onChange={handlePageChange}
                        showSizeChanger={false}
                        onShowSizeChange={handlePageSizeChange}
                        showQuickJumper={true} />
                </div>
            </Row>


            {/* Modal for selected rows */}
            <Modal
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
                width="670px"
                bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
            >
                <p style={{ fontFamily: "DM Sans", fontWeight: 400, fontSize: "20px", color: "black" }}>Select Chatbot</p>
                <hr />
                <Select
                    mode="multiple"
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="Please select"
                    // defaultValue={['a10', 'c12']}
                    onChange={handleSelectChange}
                    options={options}
                />
                <p style={{ fontSize: "15px", fontFamily: "Roboto", fontWeight: 400, display: "flex", justifyContent: "center" }}>Are you sure you want to proceed with adding these products to the chatbot and your meta account?</p>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button key="back" onClick={handleCancel} onClickCapture={() => setIsModalVisible(false)}>
                        No
                    </Button>
                    <Button key="submit" type="primary" style={{ marginLeft: "1rem" }} disabled={!selectedRows} onClick={handleAddProducts}>
                        Yes
                    </Button>
                </div>
            </Modal>

            {/* Wrap the last table inside a container div with a horizontal scroll */}

        </Col>
    );
};

export default Products;
